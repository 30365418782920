import { ChevronDownIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { Divider, Flex, HStack, IconButton, Image, Link, Skeleton, Text } from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import React, { useEffect, useState } from 'react';
import BullaLogo from 'url:../../assets/logo_orange.svg';
import { SecondaryButton } from '../inputs/buttons';
import { changeNetwork, FactoringConfig, NETWORKS, TokenDto, TokenVariant } from '../../data-lib/networks';
import { TOKEN_ROUNDING } from '../../data-lib/tokens';
import { useBullaFactoring } from '../../hooks/useBullaFactoring';
import { useCanChangeNetwork } from '../../hooks/useCanChangeNetwork';
import { useTokenBalances } from '../../hooks/useChainData';
import { useGnosisTransaction } from '../../hooks/useGnosisTransaction';
import { useTokenRepo } from '../../hooks/useTokenRepo';
import { useWeb3 } from '../../hooks/useWeb3';
import { DepositRedeemButtons } from './deposit-redeem-buttons';
import { useGnosisSafe } from '../../state/gnosis-state';

interface InfoTextProps {
    title: string;
    subtitle: string;
    tokenSybol?: string;
    mb?: string;
    ml?: string;
    externalIcon?: boolean;
    blockExplorer?: string;
    fundTokenAddress?: string;
}

export const InfoText: React.FC<InfoTextProps> = ({
    title,
    subtitle,
    tokenSybol,
    mb,
    ml,
    externalIcon,
    blockExplorer,
    fundTokenAddress,
}) => (
    <Flex direction="column" ml={ml ?? '0'}>
        <Text color="gray.600" fontSize={'14px'} mb={mb ?? '3'}>
            {title}
        </Text>
        <HStack spacing="2">
            <Text color="gray.800" fontWeight={'500'} fontSize={'16px'}>
                {subtitle}
                {externalIcon && (
                    <IconButton
                        as={Link}
                        href={`${blockExplorer}address/${fundTokenAddress}`}
                        isExternal
                        icon={<ExternalLinkIcon />}
                        aria-label="Open in explorer"
                        size="md"
                        variant="ghost"
                        _hover={{ background: 'transparent' }}
                        mb="1"
                    />
                )}
            </Text>
            {tokenSybol && (
                <Text color="gray.800" fontWeight={'500'} fontSize={'16px'}>
                    {tokenSybol}
                </Text>
            )}
        </HStack>
    </Flex>
);

type PoolInfo = {
    name: string;
    dateCreated: Date;
    fundBalance: BigNumber;
    deployedCapital: BigNumber;
    realizedGain: BigNumber;
    capitalAccount: BigNumber;
    price: BigNumber;
    tokensAvailableForRedemption: BigNumber;
    adminFeeBps: number;
    impairReserve: BigNumber;
    targetYieldBps: number;
    tokensOutstanding: BigNumber;
};

type PoolInfoDetail = {
    title: string;
    key: keyof PoolInfo;
    moreDetails?: boolean;
    value?: string | Date | number;
};

const POOL_INFO_DETAILS: PoolInfoDetail[] = [
    { title: 'Fund Balance', key: 'fundBalance' },
    { title: 'Deployed Capital', key: 'deployedCapital' },
    { title: 'Capital Account', key: 'capitalAccount' },
    { title: 'Current Price Per Token', key: 'price' },
    { title: 'Tokens Available for Redemption / Total Supply', key: 'tokensAvailableForRedemption' },
    { title: 'Admin Fee', moreDetails: true, key: 'adminFeeBps' },
    { title: 'Impair RSV', moreDetails: true, key: 'impairReserve' },
];

export type poolInfoState = { type: 'init' } | { type: 'loading' } | { type: 'not-found' } | { type: 'fetched'; poolInfo: PoolInfo };

const formatPoolInfoDetail = (
    detail: PoolInfoDetail,
    poolInfo: PoolInfo,
    tokenDecimals: number,
    tokenSymbol: string,
    fundTokenInfo: TokenDto,
): { label: string; value: string; externalIcon: boolean } => {
    const value = poolInfo[detail.key];
    let formattedValue = '';

    if (typeof value === 'number' || BigNumber.isBigNumber(value)) {
        formattedValue = Number(formatUnits(value, tokenDecimals)).toLocaleString('en-US', {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
        });
    } else if (value instanceof Date) {
        formattedValue = value.toString();
    } else {
        formattedValue = value;
    }

    const displayedTokenSymbol =
        detail.key === 'tokensAvailableForRedemption'
            ? `${fundTokenInfo.symbol} / ${Number(formatUnits(poolInfo.tokensOutstanding, tokenDecimals)).toLocaleString('en-US', {
                  minimumFractionDigits: 3,
                  maximumFractionDigits: 3,
              })} ${fundTokenInfo.symbol}`
            : tokenSymbol;
    const displayedValue = `${formattedValue}${displayedTokenSymbol ? ` ${displayedTokenSymbol} ` : ''}`;

    return { label: detail.title, value: displayedValue, externalIcon: detail.key === 'tokensAvailableForRedemption' };
};

export const PoolCard: React.FC<{
    factoringConfig: FactoringConfig;
    hasDepositPermissions: boolean;
    onViewPoolDetails: () => void;
}> = ({ factoringConfig, hasDepositPermissions, onViewPoolDetails }) => {
    const poolUnderlyingTokenDecimals = factoringConfig.poolUnderlyingToken.token.decimals;
    const poolUnderlyingTokenSymbol = factoringConfig.poolUnderlyingToken.token.symbol;
    const fundTokenInfo = factoringConfig.bullaFactoringToken.token;
    const { connectedNetwork, provider } = useWeb3();
    const { safeInfo } = useGnosisSafe();
    const poolChainId = factoringConfig.bullaFactoringToken.chainId;
    const canChangeNetwork = useCanChangeNetwork();
    const changeNetworkDisable = !canChangeNetwork || !!safeInfo;
    const [showDetails, setShowDetails] = useState(false);
    const blockExplorer = connectedNetwork ? NETWORKS[factoringConfig.bullaFactoringToken.chainId].blockExplorer : undefined;

    const [poolInfo, setPoolInfo] = useState<poolInfoState>({ type: 'init' });
    const isLoading = poolInfo.type === 'loading';

    const [_, { getPoolInfo, getTotalSupply }] = useBullaFactoring(factoringConfig);
    const { getTokenByChainIdAndAddress } = useTokenRepo();
    const { progress: gnosisTransactionProgress } = useGnosisTransaction();

    const tokenBalances = useTokenBalances({ chainId: connectedNetwork, poll: true });
    const tokenRouding =
        TOKEN_ROUNDING[getTokenByChainIdAndAddress(poolChainId)(fundTokenInfo.address.toLowerCase())?.variant ?? TokenVariant.UNKNOWN];

    const currentTokenBalance =
        tokenBalances
            .getBalanceForToken(fundTokenInfo.address)
            ?.toFixed(tokenRouding + 1)
            .toString() ?? '0';

    const holdsNoBFT = currentTokenBalance == '0';

    useEffect(() => {
        setPoolInfo({ type: 'loading' });
        const fetchData = async () => {
            try {
                const result = await getPoolInfo();
                const tokensOutstanding = await getTotalSupply();
                if (result && tokensOutstanding) {
                    setPoolInfo({ type: 'fetched', poolInfo: { ...result, tokensOutstanding } });
                } else {
                    setPoolInfo({ type: 'not-found' });
                }
            } catch (error) {
                setPoolInfo({ type: 'not-found' });
            }
        };
        fetchData();
    }, [gnosisTransactionProgress, tokenBalances]);

    const BASIC_POOL_INFO_DETAILS = POOL_INFO_DETAILS.filter(x => !x.moreDetails);

    const formattedPoolInfo =
        poolInfo.type === 'fetched'
            ? BASIC_POOL_INFO_DETAILS.map(detail =>
                  formatPoolInfoDetail(detail, poolInfo.poolInfo, poolUnderlyingTokenDecimals, poolUnderlyingTokenSymbol, fundTokenInfo),
              )
            : [];

    return (
        <Flex alignItems="center" direction={'column'} border="1px solid" borderColor={'gray.300'} borderRadius="2xl" my="6">
            <Flex direction={'row'} w="100%" justifyContent="space-between" p="8">
                <Flex alignItems="center">
                    <Image src={BullaLogo} mr="5" w="45px" />
                    {poolInfo.type === 'fetched' && (
                        <>
                            <Flex direction="column" alignItems="left" mr="14">
                                <>
                                    <Text fontSize={'18px'} as="b">
                                        {poolInfo.poolInfo.name}
                                        <IconButton
                                            as={Link}
                                            href={`${blockExplorer}address/${fundTokenInfo.address}`}
                                            isExternal
                                            icon={<ExternalLinkIcon />}
                                            aria-label="Open in explorer"
                                            size="md"
                                            variant="ghost"
                                            _hover={{ background: 'transparent' }}
                                            mb="1"
                                        />
                                    </Text>
                                    <Text color="gray.600">
                                        Created{' '}
                                        {poolInfo.poolInfo.dateCreated.toLocaleDateString('en-US', {
                                            month: 'short',
                                            day: 'numeric',
                                            year: 'numeric',
                                        })}
                                    </Text>
                                </>
                            </Flex>
                            <InfoText
                                title="Target Yield (before fees)"
                                subtitle={`${Number(poolInfo.poolInfo.targetYieldBps) / 100} %`}
                                mb="0"
                            />
                            <InfoText
                                title="Network"
                                subtitle={NETWORKS[factoringConfig.bullaFactoringToken.chainId].label}
                                mb="0"
                                ml="8"
                            />
                        </>
                    )}
                </Flex>
                {poolChainId != connectedNetwork ? (
                    <SecondaryButton isDisabled={changeNetworkDisable} onClick={() => changeNetwork(poolChainId, provider)}>
                        {canChangeNetwork ? 'Switch Network' : 'Switch Wallet Network to ' + NETWORKS[poolChainId].label}
                    </SecondaryButton>
                ) : (
                    <Flex>
                        <SecondaryButton
                            mr={2}
                            px="4"
                            borderColor={'gray.300'}
                            color="brand.bulla_blue"
                            onClick={() => onViewPoolDetails()}
                            isDisabled={isLoading}
                            border="1px"
                        >
                            View Pool Details
                        </SecondaryButton>
                        <DepositRedeemButtons
                            factoringConfig={factoringConfig}
                            isLoading={isLoading}
                            hasDepositPermissions={hasDepositPermissions}
                            holdsNoBFT={holdsNoBFT}
                        />
                    </Flex>
                )}
            </Flex>

            <Divider />

            <Flex direction={'row'} w="100%" p="8" wrap="wrap" gap={4}>
                <Flex direction="column" width="full">
                    <Flex wrap="wrap" gap={2} justifyContent="space-between">
                        {formattedPoolInfo.map(({ label, value, externalIcon }) => (
                            <Skeleton
                                key={label}
                                isLoaded={poolInfo.type === 'fetched'}
                                fadeDuration={0.6}
                                startColor="gray.300"
                                endColor="gray.500"
                                borderRadius="md"
                            >
                                <InfoText
                                    key={label}
                                    title={label}
                                    subtitle={value}
                                    externalIcon={externalIcon}
                                    blockExplorer={blockExplorer}
                                    fundTokenAddress={fundTokenInfo.address}
                                />
                            </Skeleton>
                        ))}
                    </Flex>
                </Flex>
            </Flex>

            {poolInfo.type === 'fetched' && (
                <Flex onClick={() => setShowDetails(!showDetails)} cursor={'pointer'} alignItems="left" w="100%" pl="8" pb="6" mt="-3">
                    <Text color="brand.bulla_blue" fontWeight={'600'} textAlign="left">
                        More Details
                    </Text>
                    <ChevronDownIcon color="#12525B" w="6" h="6" ml="2" />
                </Flex>
            )}

            {showDetails && poolInfo.type === 'fetched' && (
                <Flex direction={'row'} w="100%" px="8" wrap="wrap" gap={8} pb="8">
                    <Flex direction="column" width="full">
                        <Flex wrap="wrap" gap={'100px'}>
                            <InfoText title="Admin Fee" subtitle={`${Number(poolInfo.poolInfo.adminFeeBps) / 100} %`} />
                            <InfoText
                                title="Impair RSV"
                                subtitle={`${formatUnits(
                                    poolInfo.poolInfo.impairReserve,
                                    poolUnderlyingTokenDecimals,
                                )} ${poolUnderlyingTokenSymbol}`}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            )}
        </Flex>
    );
};
