import { Box, Container, Flex, Heading, HStack, Spacer, Text } from '@chakra-ui/react';
import { default as React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { ClaimType } from '../../../data-lib/data-model';
import { addressEquality } from '../../../data-lib/ethereum';
import { addDaysToToday } from '../../../data-lib/helpers';
import { NETWORKS } from '../../../data-lib/networks';
import { useLocalStorage } from '../../../hooks/useStorage';
import { TemporaryCategoriesProvider } from '../../../hooks/useTemporaryCategories';
import { useTokenRepo } from '../../../hooks/useTokenRepo';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { useWeb3 } from '../../../hooks/useWeb3';
import { useUIState } from '../../../state/ui-state';
import { STORAGE_KEYS } from '../../../tools/storage';
import { NotificationWindow } from '../../layout/page-layout';
import { BullaCloseButton } from '../common';
import { ClosedClaimConfigurationCard, OpenedClaimConfigurationCard } from './batch-claim-configuration';
import { BatchPricingModal } from './batch-pricing-modal';
import { OpenedReviewCard } from './batch-review-card';
import { BatchCreationState, BatchWizardState, initialDefaultValues, isBatchCreationState } from './batch-state';
import { ClosedDefaultValueSelectionCard, OpenedDefaultValueSelectionCard } from './default-values-cards';
import { ClosedPaymentTimeCard, OpenedSelectedPaymentCard } from './payment-time-cards';
import { StartDraftOrNewCard } from './start-new-or-draft-card';

type BatchWizardProps = {
    claimType: ClaimType;
};

export const BatchWizard = (context: BatchWizardProps) => {
    const { transactionPending, pendingTxns, alerts } = useUIState();
    const navigate = useNavigate();
    const { connectedNetwork } = useWeb3();
    const userAddress = useActingWalletAddress();
    const { tokensByChainId } = useTokenRepo();

    const [claimConfigurationOpenCloseDetailsOverride, _setClaimConfigurationOpenCloseDetailsOverride] = useState<'Open' | 'Close'>(
        'Close',
    );

    const toggleClaimConfigurationOpenCloseDetails = () =>
        _setClaimConfigurationOpenCloseDetailsOverride(claimConfigurationOpenCloseDetailsOverride == 'Open' ? 'Close' : 'Open');
    const storageKey = `${userAddress}:${connectedNetwork}:${STORAGE_KEYS.batchDrafts}`;
    const [_drafts, setDrafts] = useLocalStorage<BatchCreationState[]>(storageKey, []);
    const drafts = _drafts.filter(x => x.claimType == context.claimType);

    const initialDefaultValuesWithToken = {
        ...initialDefaultValues,
        defaultToken: tokensByChainId[connectedNetwork][0].token,
        defaultDueDate: addressEquality(userAddress, '0xbbd837143cdc4816fcd7301731f6d4a366d0cba2')
            ? addDaysToToday(40)
            : initialDefaultValues.defaultDueDate,
    };

    //@ts-ignore
    const [initialBatchId] = useState(crypto.randomUUID());

    const initialNewBatchCreationState: BatchWizardState = {
        ...(context.claimType == 'Invoice'
            ? {
                  kind: 'DefaultValueSelection',
                  defaultValues: initialDefaultValuesWithToken,
              }
            : {
                  kind: 'SelectPaymentType',
              }),
        batchId: initialBatchId,
        claimType: context.claimType,
        createdOn: new Date().toLocaleDateString(),
    };

    const initialWizardState: BatchWizardState =
        drafts.length !== 0 ? { kind: 'StartFromNewOrDraft', drafts, selectedItem: undefined } : initialNewBatchCreationState;
    const [wizardState, setWizardState] = useState<BatchWizardState>(initialWizardState);
    const [debouncedWizardState] = useDebounce(wizardState, 500);

    useEffect(() => {
        if (isBatchCreationState(debouncedWizardState) && !!debouncedWizardState.defaultValues?.defaultDescription) {
            setDrafts([...drafts.filter(x => x.batchId !== debouncedWizardState.batchId), debouncedWizardState]);
        }
    }, [debouncedWizardState]);

    const onClose = () => navigate('/');

    const onWizardComplete = () => {
        onClose();
        if (isBatchCreationState(wizardState)) {
            const newDrafts = drafts.filter(x => x.batchId !== wizardState.batchId);
            setDrafts(newDrafts);
        }
    };

    const paymentTimeCard =
        context.claimType == 'Invoice' || wizardState.kind == 'StartFromNewOrDraft' ? (
            <></>
        ) : wizardState.kind === 'SelectPaymentType' ? (
            <OpenedSelectedPaymentCard
                state={wizardState}
                setWizardState={setWizardState}
                initialDefaultValuesWithToken={initialDefaultValuesWithToken}
            />
        ) : (
            <ClosedPaymentTimeCard
                paymentTime={!wizardState.defaultValues.defaultDueDate ? 'now' : 'later'}
                setWizardState={setWizardState}
            />
        );

    const selectDefaultValuesCard =
        wizardState.kind == 'StartFromNewOrDraft' || wizardState.kind === 'SuccessState' ? (
            <></>
        ) : wizardState.kind === 'DefaultValueSelection' ? (
            <OpenedDefaultValueSelectionCard state={wizardState} setWizardState={setWizardState} claimType={context.claimType} />
        ) : wizardState.defaultValues !== undefined ? (
            <ClosedDefaultValueSelectionCard
                stateKind={wizardState.kind}
                setWizardState={setWizardState}
                batchIndex={wizardState.batchIndex}
            />
        ) : (
            <></>
        );

    const configureClaimsCard =
        wizardState.kind == 'StartFromNewOrDraft' || wizardState.kind == 'SuccessState' ? (
            <></>
        ) : wizardState.kind !== 'ClaimConfiguration' &&
          wizardState.claimDetails !== undefined &&
          wizardState.defaultValues !== undefined ? (
            <ClosedClaimConfigurationCard batchIndex={wizardState.batchIndex} setWizardState={setWizardState} />
        ) : wizardState.kind === 'ClaimConfiguration' ? (
            <OpenedClaimConfigurationCard
                state={wizardState}
                userAddress={userAddress}
                setWizardState={setWizardState}
                claimType={context.claimType}
                transactionPending={transactionPending}
                claimConfigurationOpenCloseDetailsOverride={claimConfigurationOpenCloseDetailsOverride}
                toggleClaimConfigurationOpenCloseDetails={toggleClaimConfigurationOpenCloseDetails}
            />
        ) : (
            <></>
        );

    const reviewCard = wizardState.kind === 'ReviewState' && (
        <OpenedReviewCard
            state={wizardState}
            setWizardState={setWizardState}
            claimType={context.claimType}
            claimConfigurationOpenCloseDetailsOverride={claimConfigurationOpenCloseDetailsOverride}
            toggleClaimConfigurationOpenCloseDetails={toggleClaimConfigurationOpenCloseDetails}
            onWizardComplete={onWizardComplete}
        />
    );

    const startCard = wizardState.kind == 'StartFromNewOrDraft' && (
        <StartDraftOrNewCard
            state={wizardState}
            setSelectedDraftOrNew={selected => setWizardState({ ...wizardState, selectedItem: selected })}
            onDelete={deleted => {
                setDrafts(drafts.filter(x => x.batchId !== deleted));
                setWizardState({ ...wizardState, drafts: wizardState.drafts.filter(x => x.batchId !== deleted) });
            }}
            initialNewBatchCreationState={initialNewBatchCreationState}
            setWizardState={setWizardState}
        />
    );

    return (
        <BatchPricingModal claimType={context.claimType}>
            <TemporaryCategoriesProvider>
                <Box h="100vh" display={'flex'} flexDir="column" flex="1 1 auto" overflowY="scroll" pb="12">
                    <NotificationWindow pendingTxns={pendingTxns} alerts={alerts} />
                    <Container maxW="container.xl" p={['8', '8', '16']} minH="fit-content">
                        <HStack>
                            <Box p="0" flex="1">
                                <Heading color="heading">
                                    Batch {context.claimType} on {NETWORKS[connectedNetwork].label}
                                </Heading>
                                <Text color="heading" mt="2" fontSize={'24px'} fontWeight="500" textStyle={'noWrap'}>
                                    Add recipients and {context.claimType.toLowerCase()} details
                                </Text>
                            </Box>
                            <Spacer />
                            <BullaCloseButton onClose={onClose} />
                        </HStack>
                        <Flex pt="8" direction={'column'} w="full">
                            {startCard}
                            {paymentTimeCard}
                            {selectDefaultValuesCard}
                            {configureClaimsCard}
                            {reviewCard}
                            {wizardState.kind == 'SuccessState' && <Text>We did it boys</Text>}
                        </Flex>
                    </Container>
                </Box>
            </TemporaryCategoriesProvider>
        </BatchPricingModal>
    );
};
