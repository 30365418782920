import { version } from 'ethers';
import React from 'react';
import { FactoringConfig, FactoringConfigVersion, NETWORKS } from '../data-lib/networks';
import { hasFactoringConfig } from '../pages/financing/factoring-pools-list';
import { useBullaFactoringPermissions } from './useBullaFactoringPermissions';
import { useActingWalletAddress } from './useWalletAddress';

type Pool = {
    chainId: number;
    address: string;
    factoringConfig: FactoringConfig;
    hasFactoringPermissions: boolean;
    hasDepositPermissions: boolean;
};

type FactoringAndDepositPermissionsContext =
    | 'uninitialized'
    | {
          poolsWithPermissions: Pool[];
          isLoading: boolean;
      };

const FactoringAndDepositPermissionsContext = React.createContext<FactoringAndDepositPermissionsContext>('uninitialized');

export type FactoringAddressesByChainId = Record<number, { address: string; version: FactoringConfigVersion }[]>;

export const getFactoringConfigsByChainId = (): Record<number, FactoringConfig[]> => {
    return Object.values(NETWORKS)
        .filter(hasFactoringConfig)
        .reduce((acc, network) => {
            const chainId = network.chainId;
            if (!acc[chainId]) {
                acc[chainId] = [];
            }
            acc[chainId].push(...network.factoringConfig);
            return acc;
        }, {} as Record<number, FactoringConfig[]>);
};

export const FactoringAndDepositPermissionsProvider = ({ children }: { children: React.ReactNode }) => {
    const actingWallet = useActingWalletAddress();
    const [poolsWithPermissions, setPoolsWithPermissions] = React.useState<Pool[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [_, { hasFactoringPermissions, hasDepositPermissions }] = useBullaFactoringPermissions();

    React.useEffect(() => {
        const fetchPoolPermissions = async () => {
            setIsLoading(true);
            const factoringConfigsByChainId: Record<number, FactoringConfig[]> = getFactoringConfigsByChainId();
            const accessiblePools: Pool[] = [];

            for (const [chainId, factoringConfigs] of Object.entries(factoringConfigsByChainId)) {
                const networkConfig = NETWORKS[parseInt(chainId)];

                if (!networkConfig.factoringConfig) {
                    console.warn(`Factoring configuration is missing for chain ID ${chainId}`);
                    continue;
                }

                for (const factoringConfig of factoringConfigs) {
                    const factoringPermissions = await hasFactoringPermissions(factoringConfig);
                    const depositPermissions = await hasDepositPermissions(factoringConfig);

                    accessiblePools.push({
                        chainId: parseInt(chainId),
                        address: factoringConfig.bullaFactoringToken.token.address,
                        factoringConfig,
                        hasFactoringPermissions: factoringPermissions,
                        hasDepositPermissions: depositPermissions,
                    });
                }
            }

            setPoolsWithPermissions(accessiblePools);
            setIsLoading(false);
        };

        fetchPoolPermissions();
    }, [actingWallet]);

    return (
        <FactoringAndDepositPermissionsContext.Provider value={{ poolsWithPermissions, isLoading }}>
            {children}
        </FactoringAndDepositPermissionsContext.Provider>
    );
};

export const useFactoringAndDepositPermissions = () => {
    const context = React.useContext(FactoringAndDepositPermissionsContext);
    return context === 'uninitialized' ? { poolsWithPermissions: [], isLoading: true } : context;
};
