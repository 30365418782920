import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Divider, Flex, Heading, HStack, Text } from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import { ArrowLeft } from 'phosphor-react';
import React, { useEffect, useMemo, useState } from 'react';
import { CopyableTextLabel, shortAddress } from '../../components/base/address-label';
import { WithSkeleton } from '../../components/base/skeleton';
import { InfoText, poolInfoState } from '../../components/factoring/factoring-pool-card';
import { FactoringPriceChart } from '../../components/factoring/price-chart';
import { useActingWalletAddress } from '../../hooks/useWalletAddress';
import { PositionCard, AverageCostCard } from '../../components/factoring/position-and-average-cost-card';
import { BaseBadge } from '../../components/base/status-badge';
import { TokenAmount } from '../../components/currency/token-display-amount';
import { mapBullaItemInfoToTableItem, PageSelector, TableItem } from '../../components/display/claim-table';
import { TabSwitcher } from '../../components/display/views/account-tag-view';
import {
    buildInvoiceFactoringTableFilters,
    factoringInvoiceFilterToPills,
    InvoiceFactoringTableFilter,
} from '../../components/display/views/filters/claim-filter';
import { ClearFilterPillsStack } from '../../components/display/views/filters/common';
import {
    calculatePriceChange,
    calculateTodayReturn,
    calculateTotalReturn,
    filterCashDataByTimeRange,
    filterPriceDataByTimeRange,
    mapTimestampAndCashHistory,
    mapTimestampAndPrice,
    PriceDataPoint,
    TimeRange,
} from '../../components/factoring/common';
import { DepositRedeemButtons } from '../../components/factoring/deposit-redeem-buttons';
import { DepositRedemptionSummary, DepositRedemptionTotals } from '../../components/factoring/deposit-redemption-totals';
import { ViewDetailsButton } from '../../components/inputs/buttons';
import { ColumnDefinition, ListViewCard, VIEW_COLUMN_WIDTH } from '../../components/layout/cards';
import { MaxWidthWrapper, PageLayoutProvider } from '../../components/layout/page-layout';
import { ClaimInfo, ClaimStatus, PoolDepositInfo, PoolEventInfo, PoolRedemptionInfo } from '../../data-lib/data-model';
import { addressEquality } from '../../data-lib/ethereum';
import { isClaim, isUnfactored } from '../../data-lib/helpers';
import { FactoringConfig, NETWORKS, TokenVariant } from '../../data-lib/networks';
import { TOKEN_ROUNDING } from '../../data-lib/tokens';
import { fetchFactoringHistoricalCash, fetchFactoringTokenHistoricalPrices, useBullaFactoring } from '../../hooks/useBullaFactoring';
import { useTokenBalances } from '../../hooks/useChainData';
import { useOpenBullaItem } from '../../hooks/useClaimDetailDisclosure';
import { usePoolDetailsRepo } from '../../hooks/usePoolDetailsRepo';
import { useTokenRepo } from '../../hooks/useTokenRepo';
import { toDateDisplay } from '../../tools/common';
import { CashDataPoint, CashViewChart } from '../../components/factoring/cash-chart';
import { usePagination } from '../../hooks/usePagination';

interface PoolDetailsState {
    status: 'loading' | 'error' | 'success';
    priceData: PriceDataPoint[];
    cashData: CashDataPoint[];
    latestCashIndicators: CashDataPoint | null;
    currentPrice: number | null;
    priceChange: number | null;
    poolInfo: poolInfoState;
    averageCost: number | null;
    todayReturn: { absolute: number; percentage: number };
    totalReturn: { absolute: number; percentage: number };
    depositRedemptionSummary: DepositRedemptionSummary;
}

interface PoolDetailsViewProps {
    factoringConfig: FactoringConfig;
    onBack: () => void;
    hasDepositPermissions: boolean;
}

type PoolTableDetailTab = 'DepositRedemptions' | 'Invoices';
type PoolGraphTab = 'BullaFinanceToken' | 'CashView';

const getHeaders = (tab: PoolTableDetailTab): ColumnDefinition[] => {
    switch (tab) {
        case 'DepositRedemptions':
            return [
                { label: 'WALLET', relativeColumnWidth: '1fr' },
                { label: 'DATE', relativeColumnWidth: '1fr' },
                { label: 'ACTION', relativeColumnWidth: '1fr' },
                { label: 'AMOUNT', relativeColumnWidth: '1fr' },
                { label: 'SHARE PRICE', relativeColumnWidth: '1fr' },
                { label: '', relativeColumnWidth: VIEW_COLUMN_WIDTH },
            ];
        case 'Invoices':
            return [
                { label: 'DEBTOR', relativeColumnWidth: '1fr' },
                { label: 'ORIGINAL CREDITOR', relativeColumnWidth: '1fr' },
                { label: 'STATUS', relativeColumnWidth: '1fr' },
                { label: 'CREATED DATE', relativeColumnWidth: '1fr' },
                { label: 'DUE DATE', relativeColumnWidth: '1fr' },
                { label: 'DESCRIPTION', relativeColumnWidth: '1fr' },
                { label: 'INVOICE AMOUNT', relativeColumnWidth: '1fr' },
                { label: 'FEE', relativeColumnWidth: '1fr' },
                { label: '', relativeColumnWidth: VIEW_COLUMN_WIDTH },
            ];
    }
};

const filterPoolEvents = (
    events: (PoolDepositInfo | PoolRedemptionInfo)[],
    factoringConfig: FactoringConfig,
): (PoolDepositInfo | PoolRedemptionInfo)[] => {
    return events.filter(
        event =>
            event.chainId === factoringConfig.bullaFactoringToken.chainId &&
            addressEquality(event.poolAddress, factoringConfig.bullaFactoringToken.token.address),
    );
};

const filterFactoredInvoices = (claims: ClaimInfo[], factoringConfig: FactoringConfig): ClaimInfo[] => {
    return claims.filter(
        claim =>
            claim.chainId === factoringConfig.bullaFactoringToken.chainId &&
            claim.logs.some(
                log => 'poolAddress' in log && addressEquality(log.poolAddress, factoringConfig.bullaFactoringToken.token.address),
            ),
    );
};

export type InvoiceTableFactoringFilterValues = {
    search: string | undefined;
    date: { startDate?: Date; endDate?: Date };
    selectedWallets: Set<string>;
    claimStatus?: ClaimStatus;
};

export const emptyFactoringInvoiceTableFilterValues: InvoiceTableFactoringFilterValues = {
    search: '',
    date: { startDate: undefined, endDate: undefined },
    selectedWallets: new Set(),
};

export const PoolDetailsView: React.FC<PoolDetailsViewProps> = ({ factoringConfig, onBack, hasDepositPermissions }) => {
    const { getTokenByChainIdAndAddress } = useTokenRepo();
    const [_, { getPoolInfo, getTotalSupply, calculateAverageCostPerUser }] = useBullaFactoring(factoringConfig);
    const userAddress = useActingWalletAddress();
    const fundTokenInfo = factoringConfig.bullaFactoringToken.token;
    const openItem = useOpenBullaItem();
    const { poolDeposits, poolRedemptions, factoredInvoices } = usePoolDetailsRepo();

    const currentPoolDeposits = useMemo(() => filterPoolEvents(poolDeposits, factoringConfig), [poolDeposits, factoringConfig]);
    const currentPoolRedemptions = useMemo(() => filterPoolEvents(poolRedemptions, factoringConfig), [poolRedemptions, factoringConfig]);
    const currentFactoredInvoices = useMemo(
        () => filterFactoredInvoices(factoredInvoices, factoringConfig),
        [factoredInvoices, factoringConfig],
    );

    const [filters, setFilters] = useState<InvoiceTableFactoringFilterValues>(emptyFactoringInvoiceTableFilterValues);
    const [claimFilters, setClaimFilters] = useState<((item: TableItem) => boolean)[]>([]);

    useEffect(() => {
        setClaimFilters(buildInvoiceFactoringTableFilters(filters));
    }, [filters]);

    const invoiceTableItems: TableItem[] = mapBullaItemInfoToTableItem(currentFactoredInvoices, userAddress, openItem);
    const filterItems = (items: TableItem[]) => claimFilters.reduce((claims, filterFunc) => claims.filter(filterFunc), [...items]);
    const filteredInvoiceTableItems = filterItems(invoiceTableItems);

    const poolChainId = factoringConfig.bullaFactoringToken.chainId;
    const underlyingTokenInfo = factoringConfig.poolUnderlyingToken.token;
    const tokenBalances = useTokenBalances({ chainId: poolChainId, poll: true });
    const tokenRouding =
        TOKEN_ROUNDING[getTokenByChainIdAndAddress(poolChainId)(fundTokenInfo.address.toLowerCase())?.variant ?? TokenVariant.UNKNOWN];

    const currentTokenBalance =
        tokenBalances
            .getBalanceForToken(fundTokenInfo.address)
            ?.toFixed(tokenRouding + 1)
            .toString() ?? '0';

    const holdsNoBFT = currentTokenBalance == '0';

    const [timeRange, setTimeRange] = useState<TimeRange>('1W');
    const [state, setState] = useState<PoolDetailsState>({
        status: 'loading',
        priceData: [],
        cashData: [],
        latestCashIndicators: null,
        currentPrice: null,
        priceChange: null,
        poolInfo: { type: 'init' },
        averageCost: null,
        todayReturn: { absolute: 0, percentage: 0 },
        totalReturn: { absolute: 0, percentage: 0 },
        depositRedemptionSummary: {
            totalDeposits: BigNumber.from(0),
            totalRedemptions: BigNumber.from(0),
            total: BigNumber.from(0),
        },
    });
    const [activeTableTab, setActiveTableTab] = useState<PoolTableDetailTab>('DepositRedemptions');
    const [activeGraphTab, setActiveGraphTab] = useState<PoolGraphTab>('BullaFinanceToken');

    const [_depositAndRedemptionTableItems, setDepositAndRedemptionTableItems] = useState<TableItem[]>([]);

    const filteredPriceData = useMemo(() => filterPriceDataByTimeRange(state.priceData, timeRange), [state.priceData, timeRange]);
    const filteredCashData = useMemo(() => filterCashDataByTimeRange(state.cashData, timeRange), [state.cashData, timeRange]);

    const yAxisPriceDomain = useMemo(() => {
        if (filteredPriceData.length === 0) return [0, 2];

        const maxPrice = Math.max(...filteredPriceData.map(d => d.price));
        const minPrice = Math.min(...filteredPriceData.map(d => d.price));

        const padding = (maxPrice - minPrice) * 0.5;
        return [Math.max(0, minPrice - padding), Math.min(2, maxPrice + padding)];
    }, [filteredPriceData]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [historicalPrices, historicalCash, poolInfoResult, tokensOutstanding, averageCost] = await Promise.all([
                    fetchFactoringTokenHistoricalPrices(poolChainId, fundTokenInfo.address),
                    fetchFactoringHistoricalCash(poolChainId, fundTokenInfo.address),
                    getPoolInfo(),
                    getTotalSupply(),
                    userAddress ? calculateAverageCostPerUser(userAddress) : null,
                ]);

                const mappedPrices: PriceDataPoint[] = mapTimestampAndPrice(historicalPrices, fundTokenInfo.decimals);
                const mappedHistoricalCash: CashDataPoint[] = mapTimestampAndCashHistory(historicalCash, fundTokenInfo.decimals);
                const latestPrice = mappedPrices.length > 0 ? mappedPrices[mappedPrices.length - 1].price : null;
                const latestCashIndicators: CashDataPoint | null =
                    mappedHistoricalCash.length > 0 ? mappedHistoricalCash[mappedHistoricalCash.length - 1] : null;
                const priceChange = calculatePriceChange(mappedPrices);

                const todayReturn = calculateTodayReturn(mappedPrices, latestPrice);
                const totalReturn = calculateTotalReturn(averageCost, latestPrice, currentTokenBalance);

                const poolDepositsAndRedemption = [...currentPoolDeposits, ...currentPoolRedemptions];
                const tableItems = mapBullaItemInfoToTableItem(poolDepositsAndRedemption, userAddress, openItem);
                setDepositAndRedemptionTableItems(tableItems);

                const summary = poolDepositsAndRedemption.reduce(
                    (acc, event) => {
                        const amount = BigNumber.from(event.paidAmount);
                        if (event.__type === 'PoolDeposit') {
                            acc.totalDeposits = acc.totalDeposits.add(amount);
                        } else {
                            acc.totalRedemptions = acc.totalRedemptions.add(amount);
                        }
                        return acc;
                    },
                    { totalDeposits: BigNumber.from(0), totalRedemptions: BigNumber.from(0) },
                );

                const total = summary.totalDeposits.sub(summary.totalRedemptions);

                setState({
                    status: 'success',
                    priceData: mappedPrices,
                    cashData: mappedHistoricalCash,
                    latestCashIndicators,
                    currentPrice: latestPrice,
                    priceChange,
                    poolInfo:
                        poolInfoResult && tokensOutstanding
                            ? { type: 'fetched', poolInfo: { ...poolInfoResult, tokensOutstanding } }
                            : { type: 'not-found' },
                    averageCost,
                    todayReturn,
                    totalReturn,
                    depositRedemptionSummary: {
                        ...summary,
                        total,
                    },
                });
            } catch (error) {
                setState(prevState => ({ ...prevState, status: 'error' }));
            }
        };

        fetchData();
    }, [poolChainId, fundTokenInfo.address, fundTokenInfo.decimals, userAddress, currentTokenBalance]);

    const sortedDepositAndRedemptionTableItems = [..._depositAndRedemptionTableItems].sort(
        (a, b) => b.displayDate.getTime() - a.displayDate.getTime(),
    );
    const pageSelectorPropsDepositAndRedemption = usePagination(sortedDepositAndRedemptionTableItems);
    const visibleDepositAndRedemptionTableItems = pageSelectorPropsDepositAndRedemption.shownItems;

    const depositAndRedemptionTableItems = React.useMemo(() => {
        return visibleDepositAndRedemptionTableItems.map(item => {
            const isDeposit = item.__type === 'PoolDeposit';
            const amountDisplay = (
                <TokenAmount amount={BigNumber.from(item.paidAmount)} tokenInfo={factoringConfig.poolUnderlyingToken} withRounding />
            );
            const priceDisplay = (
                <TokenAmount
                    amount={BigNumber.from((item as PoolEventInfo).priceAfterTransaction)}
                    tokenInfo={factoringConfig.poolUnderlyingToken}
                    withRounding
                />
            );
            const date = toDateDisplay(item.displayDate);
            const action = isDeposit ? (
                <BaseBadge bg="#ECFDF3" color="#067647" border="1px solid #ABEFC6">
                    Deposit
                </BaseBadge>
            ) : (
                <BaseBadge bg="#FDE7E7" color="#B91C1C" border="1px solid #FCA5A5">
                    Redemption
                </BaseBadge>
            );

            return {
                columnValues: [
                    <CopyableTextLabel
                        displayValue={shortAddress(isDeposit ? item.debtor : item.creditor, 8)}
                        children={isDeposit ? item.debtor : item.creditor}
                        variant="address"
                    />,
                    date,
                    action,
                    amountDisplay,
                    priceDisplay,
                    <ViewDetailsButton onClick={item.onClick} />,
                ],
            };
        });
    }, [
        visibleDepositAndRedemptionTableItems,
        _depositAndRedemptionTableItems,
        fundTokenInfo,
        underlyingTokenInfo,
        poolChainId,
        factoringConfig,
    ]);

    const sortedFactoringInvoicesTableItems = [...filteredInvoiceTableItems].sort(
        (a, b) => b.displayDate.getTime() - a.displayDate.getTime(),
    );
    const pageSelectorPropsFactoringInvoices = usePagination(sortedFactoringInvoicesTableItems);
    const visibleFactoringInvoicesTableItems = pageSelectorPropsFactoringInvoices.shownItems;

    const factoringInvoicesTableItems = React.useMemo(() => {
        return visibleFactoringInvoicesTableItems.map(item => {
            const amountDisplay = (
                <TokenAmount amount={BigNumber.from(item.displayAmount)} tokenInfo={factoringConfig.poolUnderlyingToken} withRounding />
            );
            const dueDate = toDateDisplay(item.displayDate);
            const createdDate = toDateDisplay(item.created);

            const unfactoredClaim = item.__type === 'Claim' ? isUnfactored(item) : false;

            const fee = item.factoringFees ? (
                <TokenAmount amount={item.factoringFees} tokenInfo={factoringConfig.poolUnderlyingToken} withRounding />
            ) : null;

            const action = unfactoredClaim ? (
                <BaseBadge bg="#FEF3F2" color="#B42318" border="1px solid #FECDCA">
                    Unfactored
                </BaseBadge>
            ) : item.itemStatus === 'Paid' ? (
                <BaseBadge bg="#ECFDF3" color="#067647" border="1px solid #ABEFC6">
                    Paid
                </BaseBadge>
            ) : (
                <BaseBadge bg="#FFFAEB" color="#B54708" border="1px solid #FEDF89">
                    Pending
                </BaseBadge>
            );

            const originalCreditor = item.originalCreditor ? (
                <CopyableTextLabel
                    displayValue={shortAddress(item.originalCreditor, 8)}
                    children={item.originalCreditor}
                    variant="address"
                />
            ) : null;

            return {
                columnValues: [
                    <CopyableTextLabel displayValue={shortAddress(item.debtor, 8)} children={item.debtor} variant="address" />,
                    originalCreditor,
                    action,
                    createdDate,
                    dueDate,
                    item.description,
                    amountDisplay,
                    fee,
                    <ViewDetailsButton onClick={item.onClick} />,
                ],
            };
        });
    }, [visibleFactoringInvoicesTableItems, filteredInvoiceTableItems]);

    useEffect(() => {
        const change = calculatePriceChange(filteredPriceData);
        setState(prevState => ({ ...prevState, priceChange: change }));
    }, [filteredPriceData]);

    return (
        <PageLayoutProvider>
            <Flex p="12" direction="column" flex="1">
                <MaxWidthWrapper>
                    <Flex direction={'row'} w="100%" justifyContent="space-between" pt="8">
                        <Breadcrumb textColor={'gray.700'}>
                            <BreadcrumbItem>
                                <BreadcrumbLink onClick={onBack}>Bulla Financing Pools</BreadcrumbLink>
                            </BreadcrumbItem>
                            <BreadcrumbItem isCurrentPage>
                                <Text fontWeight="semibold" fontSize="md">
                                    {factoringConfig.poolName}
                                </Text>
                            </BreadcrumbItem>
                        </Breadcrumb>

                        <HStack cursor="pointer" onClick={onBack} color="gray.600">
                            <ArrowLeft size={18} weight="bold" />
                            <Text fontWeight="semibold" fontSize="md">
                                Back to Pool List
                            </Text>
                        </HStack>
                    </Flex>

                    <Flex direction={'row'} w="100%" justifyContent="space-between" pt="8">
                        <Flex>
                            <Heading w="100%">{factoringConfig.poolName}</Heading>
                        </Flex>
                        <DepositRedeemButtons
                            factoringConfig={factoringConfig}
                            hasDepositPermissions={hasDepositPermissions}
                            holdsNoBFT={holdsNoBFT}
                        />
                    </Flex>

                    {state.status === 'success' && state.poolInfo.type === 'fetched' && fundTokenInfo.name ? (
                        <Box mt="8" borderWidth="1px" borderRadius="lg" p="6" shadow="md">
                            <Box mb="6">
                                <TabSwitcher
                                    tab={activeGraphTab}
                                    setTab={setActiveGraphTab}
                                    options={[
                                        {
                                            label: 'Bulla Finance Token',
                                            value: 'BullaFinanceToken',
                                        },
                                        {
                                            label: 'Cash View',
                                            value: 'CashView',
                                        },
                                    ]}
                                    activeColor="brand.bulla_orange"
                                />
                            </Box>
                            {activeGraphTab === 'BullaFinanceToken' ? (
                                <FactoringPriceChart
                                    tokenName={fundTokenInfo.name}
                                    currentPrice={state.currentPrice}
                                    priceChange={state.priceChange}
                                    priceData={filteredPriceData}
                                    timeRange={timeRange}
                                    setTimeRange={setTimeRange}
                                    yAxisDomain={yAxisPriceDomain}
                                    underlyingToken={underlyingTokenInfo}
                                />
                            ) : (
                                <CashViewChart
                                    underlyingToken={underlyingTokenInfo}
                                    chartData={filteredCashData}
                                    latestCashIndicators={state.latestCashIndicators}
                                    timeRange={timeRange}
                                    setTimeRange={setTimeRange}
                                />
                            )}
                            <Divider mt="8" mb="4" width="calc(103%)" mx="-6" />
                            <Box p="2">
                                <Text fontSize="xl" fontWeight="bold" mb="6">
                                    Pool Stats
                                </Text>
                                <Flex direction="row" wrap="wrap" gap={24}>
                                    <InfoText
                                        title="Fund Balance"
                                        subtitle={`${Number(
                                            formatUnits(state.poolInfo.poolInfo.fundBalance, underlyingTokenInfo.decimals),
                                        ).toLocaleString('en-US', {
                                            minimumFractionDigits: 3,
                                            maximumFractionDigits: 3,
                                        })} ${underlyingTokenInfo.symbol}`}
                                    />
                                    <InfoText
                                        title="Capital Account"
                                        subtitle={`${Number(
                                            formatUnits(state.poolInfo.poolInfo.capitalAccount, underlyingTokenInfo.decimals),
                                        ).toLocaleString('en-US', {
                                            minimumFractionDigits: 3,
                                            maximumFractionDigits: 3,
                                        })} ${underlyingTokenInfo.symbol}`}
                                    />
                                    <InfoText
                                        title="Total Supply"
                                        subtitle={`${Number(
                                            formatUnits(state.poolInfo.poolInfo.tokensOutstanding, fundTokenInfo.decimals),
                                        ).toLocaleString('en-US', {
                                            minimumFractionDigits: 3,
                                            maximumFractionDigits: 3,
                                        })} ${fundTokenInfo.symbol}`}
                                    />
                                    <InfoText title="Currency" subtitle={underlyingTokenInfo.symbol} />
                                    <InfoText title="Network" subtitle={NETWORKS[factoringConfig.bullaFactoringToken.chainId].name} />
                                    <InfoText title="Token" subtitle={fundTokenInfo.symbol} />
                                </Flex>
                            </Box>
                        </Box>
                    ) : (
                        <Box mt="6">
                            <WithSkeleton isLoading={true} fixedWidth="100%" height="600px">
                                <Text>Can't see me</Text>
                            </WithSkeleton>
                        </Box>
                    )}

                    {hasDepositPermissions && !holdsNoBFT && (
                        <Flex direction="row" justify="space-between" mt="6" gap="8">
                            <PositionCard
                                currentTokenBalance={currentTokenBalance}
                                currentPrice={state.currentPrice}
                                todayReturn={state.todayReturn}
                                totalReturn={state.totalReturn}
                                underlyingTokenSymbol={underlyingTokenInfo.symbol}
                            />
                            <AverageCostCard
                                averageCost={state.averageCost}
                                currentTokenBalance={currentTokenBalance}
                                tokensAvailableForRedemption={
                                    state.poolInfo.type === 'fetched' ? state.poolInfo.poolInfo.tokensAvailableForRedemption : null
                                }
                                fundTokenInfo={fundTokenInfo}
                                underlyingTokenSymbol={underlyingTokenInfo.symbol}
                            />
                        </Flex>
                    )}

                    <Box my="6">
                        <TabSwitcher
                            tab={activeTableTab}
                            setTab={setActiveTableTab}
                            options={[
                                {
                                    label: 'Deposit & Redemptions',
                                    value: 'DepositRedemptions',
                                },
                                {
                                    label: 'Invoices',
                                    value: 'Invoices',
                                },
                            ]}
                            activeColor="brand.bulla_orange"
                        />
                        <Divider transform="translate(0, -2px)" />
                    </Box>

                    {activeTableTab === 'DepositRedemptions' ? (
                        <>
                            <DepositRedemptionTotals
                                summary={state.depositRedemptionSummary}
                                poolUnderlyingToken={factoringConfig.poolUnderlyingToken}
                            />

                            <Box my="6">
                                <ListViewCard
                                    headers={getHeaders(activeTableTab)}
                                    displayedListItems={depositAndRedemptionTableItems}
                                    emptyMessage="No pool data available"
                                    rowSizeOverride="5em"
                                    alternateRowColor="#F4F6F9"
                                    totalItemCount={depositAndRedemptionTableItems.length}
                                />
                                <PageSelector {...pageSelectorPropsDepositAndRedemption} justifySelf="center" pt="6" />
                            </Box>
                        </>
                    ) : (
                        <Box my="6">
                            <InvoiceFactoringTableFilter searchPlaceholder="invoices" filters={filters} setFilters={setFilters} />
                            <ClearFilterPillsStack
                                pb="4"
                                clearAll={() => setFilters(emptyFactoringInvoiceTableFilterValues)}
                                filters={filters}
                                filtersToPills={factoringInvoiceFilterToPills(setFilters)}
                            />
                            <ListViewCard
                                headers={getHeaders(activeTableTab)}
                                displayedListItems={factoringInvoicesTableItems}
                                emptyMessage="No invoices available"
                                rowSizeOverride="5em"
                                alternateRowColor="#F4F6F9"
                            />
                            <PageSelector {...pageSelectorPropsFactoringInvoices} justifySelf="center" pt="6" />
                        </Box>
                    )}
                </MaxWidthWrapper>
            </Flex>
        </PageLayoutProvider>
    );
};
