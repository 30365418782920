import { Button } from '@chakra-ui/button';
import { FormControl, FormErrorMessage, FormHelperText } from '@chakra-ui/form-control';
import { ChevronDownIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { Input, InputGroup, InputLeftAddon, InputRightElement } from '@chakra-ui/input';
import { Flex, HStack, Text } from '@chakra-ui/layout';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import {
    Box,
    ButtonProps,
    Collapse,
    Fade,
    FormLabel,
    IconButton,
    Image,
    InputLeftElement,
    InputRightAddon,
    Link,
    Portal,
    Radio,
    RadioGroup,
    Stack,
    StackProps,
    useBoolean,
    VisuallyHidden,
} from '@chakra-ui/react';
import { chakra, StyleProps } from '@chakra-ui/system';
import { useCombobox } from 'downshift';
import { FieldInputProps, FormikErrors, FormikTouched } from 'formik';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import DeleteIcon from 'url:../../../assets/delete.svg';
import * as Yup from 'yup';
import { QRCodeIcon } from '../../../assets/svgs';
import { ClaimType } from '../../../data-lib/data-model';
import { EthAddress } from '../../../data-lib/ethereum';
import { addDaysToToday } from '../../../data-lib/helpers';
import { ChainId, NETWORKS, TokenDto, TokenInfo, TokenVariant } from '../../../data-lib/networks';
import { TokenDisplay, TOKEN_ROUNDING } from '../../../data-lib/tokens';
import { useAttachmentGenerationState } from '../../../hooks/useAttachmentGenerationState';
import { useTokenBalances, useTokenPrices } from '../../../hooks/useChainData';
import { useCompanyDetailsRepo } from '../../../hooks/useCompanyDetailsRepo';
import { Contact, isContactsReady, useExtendedContacts } from '../../../hooks/useExtendedContacts';
import { useMembership } from '../../../hooks/useMembership';
import { useSearchFilter } from '../../../hooks/useSearchFilter';
import { useTokenRepo } from '../../../hooks/useTokenRepo';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { useWeb3 } from '../../../hooks/useWeb3';
import { SortedSafeInfo } from '../../../pages/onboard/gnosis-safe-connect';
import { ComapanyDetailsFromClaimCreationModal } from '../../../pages/settings/company-details/company-details-page';
import { isUserReady, useAuth } from '../../../state/auth-state';
import { calculateDateDifference, toUSD } from '../../../tools/common';
import { openIpfsLink } from '../../../tools/ipfs';
import { shortAddress } from '../../base/address-label';
import { HighlightedText } from '../../base/highlighted-text';
import { WithSkeleton } from '../../base/skeleton';
import DatePicker from '../../date-picker';
import { AccountTagViewer } from '../../inputs/account-tag-input';
import { BullaAttachmentInput, FileNameLabel } from '../../inputs/attachment-input';
import { BullaBlueTextButton, TextButton } from '../../inputs/buttons';
import { usePageScrollRef } from '../../layout/page-layout';
import { PremiumPricingModal } from '../premium-pricing-modal';
import { BullaFileObject, BullaItemAttachment, InvoiceType, UploadedFile } from './create-claim-modal';
import { QrScannerModal } from './qr-scanner-modal';

type SetFieldValue = (field: string, value: any, shouldValidate?: boolean | undefined) => void;
export type FormiklessField = { name: string; value: any; onBlur?: (x: any) => void; onChange?: (e: React.ChangeEvent<any>) => void };

export const emptyFields = {
    claimAmount: '0',
    instantPayment: false,
    recipient: '',
    description: '',
    dueBy: addDaysToToday(30),
    tags: [] as string[],
    attachment: undefined,
    notes: '',
    emailAddress: '',
    emailMessage: '',
    emailCC: '',
    invoiceType: InvoiceType.NFT,
};

export type BaseInputProps = {
    error?: FormikErrors<any> | string | undefined | string[];
    touched?: FormikTouched<any> | boolean;
    field: FormiklessField | FieldInputProps<any>;
    isDisabled: boolean;
    label?: string;
    required?: boolean;
};

type RecipientFieldProps = BaseInputProps & {
    setRecipient: (recipient: string) => void;
    initialValue?: string;
    setEmailAddress?: (emailAddress: string) => void;
    dropdownModalRef?: React.RefObject<HTMLDivElement>;
    fromLink?: boolean;
    chainId?: ChainId;
    placeholder?: string;
    hasQrScanner?: boolean;
    inputLeftElement?: React.ReactNode;
    userSafes?: SortedSafeInfo[];
    selectableWallets?: Set<string>;
};

const OptionalLabel = () => (
    <FormHelperText mt="1" mx="1.5">
        (Optional)
    </FormHelperText>
);

export const canConvertToBigNumber = (str: string) => str.trim() !== '' && str !== '.' && str !== '-' && str !== '-.';
export const isTokenAmountValid = (claimAmountString: string, tokenDecimals: number) =>
    (claimAmountString.split('.')[1]?.length ?? 0) <= tokenDecimals;
export const isNumberInputValid = (str: string) => {
    const regex = new RegExp(/^[0-9]*.?[0-9]*$/);
    return regex.test(str) && !isNaN(+str) && str !== '.' && str.search(' ') < 0;
};

export const FormLabelWithRequiredness = ({
    isRequired,
    label,
    fieldName,
    children,
    fromLink,
}: {
    isRequired: boolean;
    label: string;
    fieldName: string;
    children?: React.ReactNode;
    fromLink?: boolean;
}) => {
    const labelSpan = <chakra.span>{label}</chakra.span>;
    const formLabel = isRequired ? (
        labelSpan
    ) : (
        <Flex>
            {labelSpan}
            {!fromLink && <OptionalLabel />}
        </Flex>
    );

    return (
        <FormLabel htmlFor={fieldName}>
            {formLabel}
            {children}
        </FormLabel>
    );
};

type DropdownSearchProps<T> = {
    allItems: T[];
    itemToString: (item: T) => string;
    initialValue?: string;
    setValue: (input: string) => void;
    itemToSearchableStrings: (item: T) => string[];
    onItemClick: (item: T, setInputValue: (str: string) => void) => void;
    inputRightElement?: (setInputValue: (value: string) => void) => React.ReactNode;
    inputLeftElement?: React.ReactNode;
    placeholder?: string;
    isContentLoading?: boolean;
    itemToDisplayValue: (item: T) => string;
    emptyItem: T;
    fieldName: string;
    isDisabled: boolean;
    onSubmit?: (inputValue: string, setInputValue: (str: string) => void) => void;
    dropdownPortalRef?: React.RefObject<HTMLDivElement>;
    defaultAction?: { stringToItem: (str: string) => T; stringToElement: (inputValue: string) => React.ReactNode };
    tagsDisplayed?: string[];
    fromLink?: boolean;
    chainId?: ChainId;
    showSubmitButton?: boolean;
};

export const DropdownSearch = <T,>({
    allItems,
    itemToString,
    initialValue,
    setValue,
    itemToSearchableStrings,
    onItemClick,
    fieldName,
    isDisabled,
    placeholder,
    inputRightElement,
    inputLeftElement,
    isContentLoading,
    itemToDisplayValue,
    emptyItem,
    onSubmit: _onSubmit,
    dropdownPortalRef,
    defaultAction,
    tagsDisplayed,
    fromLink,
    chainId,
    showSubmitButton,
}: DropdownSearchProps<T>) => {
    const [filteredItems, query, setQuery] = useSearchFilter(allItems, itemToSearchableStrings);
    const [scrollTop, setScrollTop] = React.useState(0);
    const blockExplorer = chainId ? NETWORKS[chainId].blockExplorer : undefined;
    const inputRef = useRef<HTMLInputElement>(null);
    const scrollRef = usePageScrollRef();
    const scrollRefCurrent = scrollRef?.current;

    const onScroll = React.useCallback((e: any) => {
        setScrollTop(e.target.scrollTop ?? 0);
    }, []);

    React.useEffect(() => {
        if (!!scrollRefCurrent) setScrollTop(scrollRefCurrent.scrollTop);
        scrollRefCurrent?.addEventListener('scroll', onScroll);
        return () => scrollRefCurrent?.removeEventListener('scroll', onScroll);
    }, [scrollRefCurrent ?? 'none']);

    const inputBoundingRect = inputRef.current?.getBoundingClientRect();
    const {
        isOpen,
        getItemProps,
        getMenuProps,
        highlightedIndex,
        openMenu,
        closeMenu,
        getInputProps,
        setInputValue,
        getComboboxProps,
        inputValue,
    } = useCombobox({
        items: filteredItems,
        itemToString: (item: T | null) => (!!item ? itemToString(item) : ''),
        onInputValueChange: ({ inputValue }) => {
            setValue(inputValue ?? '');
            setQuery(inputValue ?? '');
        },
        onSelectedItemChange: ({ selectedItem }) => {
            if (selectedItem) {
                onItemClick(selectedItem, setInputValue);
                closeMenu();
            }
        },
    });

    const focus = () => {
        setQuery('');
        openMenu();
    };

    const show = isOpen && (filteredItems.length > 0 || !!defaultAction);

    const onSubmit = () => {
        if (_onSubmit) {
            _onSubmit(inputValue, setInputValue);
            closeMenu();
        }
    };

    const itemStack = (
        <Stack
            mt="2"
            maxH="40"
            bg="white"
            spacing="0"
            border="xl"
            boxShadow="2xl"
            zIndex={100}
            left={`${(inputBoundingRect?.left || 0) - (dropdownPortalRef?.current?.offsetLeft ?? 0)}px`}
            top={(inputBoundingRect?.bottom || 0) - (dropdownPortalRef?.current?.offsetTop ?? 0) + scrollTop}
            w={!!inputRef?.current ? inputRef.current?.offsetWidth : '100%'}
            overflowY="auto"
            borderRadius="md"
            {...getMenuProps()}
            pos="absolute"
        >
            <Collapse in={show} style={{ overflowY: 'auto' }}>
                <Box p="0">
                    {!isContentLoading
                        ? filteredItems.map((item, index) => {
                              const itemProps = getItemProps({ index, item });
                              return (
                                  <Stack
                                      py="4"
                                      px="6"
                                      w="100%"
                                      key={itemToString(item)}
                                      {...itemProps}
                                      cursor={highlightedIndex === index ? 'pointer' : 'default'}
                                      bg={highlightedIndex === index ? 'gray.100' : 'white'}
                                      onMouseDown={itemProps.onClick}
                                      onClick={undefined}
                                  >
                                      <HighlightedText search={query}>
                                          <>{itemToDisplayValue(item)}</>
                                      </HighlightedText>
                                      <VisuallyHidden>{itemToString(item)}</VisuallyHidden>
                                  </Stack>
                              );
                          })
                        : [...Array(3)].map((_, index) => (
                              <Stack py="2" px="6" w="100%" key={index} {...getItemProps({ index, item: emptyItem })}>
                                  <WithSkeleton randomW children={'hidden'} isLoading={true} />
                                  <WithSkeleton randomW children={'hidden'} isLoading={true} />
                              </Stack>
                          ))}
                    {inputValue.trim() !== '' &&
                        !filteredItems.map(x => itemToDisplayValue(x).toLowerCase()).includes(inputValue.trim().toLowerCase()) &&
                        defaultAction && (
                            <Stack
                                py="4"
                                px="6"
                                w="100%"
                                key={'create-new-category'}
                                cursor={'default'}
                                bg={'white'}
                                _hover={{ cursor: 'pointer', bg: 'gray.100' }}
                                onClick={() => {
                                    if (inputValue) {
                                        onItemClick(defaultAction.stringToItem(inputValue), setInputValue);
                                        closeMenu();
                                    }
                                }}
                            >
                                {defaultAction.stringToElement(inputValue)}
                            </Stack>
                        )}
                </Box>
            </Collapse>
        </Stack>
    );
    const { value, onBlur, ...inputProsWithoutValue } = getInputProps();

    return (
        <Box {...getComboboxProps()}>
            <form onSubmit={() => {}} action={'javascript:void(0);'}>
                <InputGroup ref={inputRef}>
                    {inputLeftElement && <InputLeftElement pointerEvents="none">{inputLeftElement}</InputLeftElement>}

                    <Input
                        id={fieldName}
                        onFocus={focus}
                        name={fieldName}
                        {...inputProsWithoutValue}
                        value={initialValue ?? value}
                        onDoubleClick={focus}
                        bg="white"
                        autoComplete="off"
                        placeholder={placeholder}
                        isDisabled={isDisabled}
                        {...disabledInputProps}
                        onKeyDown={e => e.key == 'Enter' && onSubmit()}
                        onBlur={e => {
                            onBlur(e);
                            if (e.target.value.trim() !== '' && !tagsDisplayed?.includes(e.target.value.trim())) {
                                onSubmit();
                            } else {
                                setInputValue('');
                            }
                        }}
                        _placeholder={{ fontWeight: '500', color: 'rgba(52, 64, 84, 1)' }}
                        fontWeight="500"
                        color="rgba(52, 64, 84, 1)"
                        fontSize={'14px'}
                        backgroundColor={'white'}
                    />
                    {showSubmitButton && (inputValue?.trim() ?? '') !== '' && (
                        <InputRightElement mx="2">
                            <Button
                                bg="green"
                                color="white"
                                onClick={onSubmit}
                                borderRadius="8"
                                fontSize={'10'}
                                my="auto"
                                p="3"
                                h="0"
                                _hover={{ bg: 'green' }}
                            >
                                Add
                            </Button>
                        </InputRightElement>
                    )}
                    {inputRightElement && !isDisabled && <InputRightAddon>{inputRightElement(setInputValue)}</InputRightAddon>}
                    {fromLink && blockExplorer && (
                        <InputRightElement>
                            <IconButton
                                as={Link}
                                href={`${blockExplorer}address/${initialValue}`}
                                isExternal
                                icon={<ExternalLinkIcon />}
                                aria-label="Open in explorer"
                                size="l"
                                variant="ghost"
                                _hover={{ background: 'transparent' }}
                            />
                        </InputRightElement>
                    )}
                </InputGroup>
            </form>
            {dropdownPortalRef ? <Portal containerRef={dropdownPortalRef}>{itemStack}</Portal> : itemStack}
        </Box>
    );
};

export const RecipientField = ({
    error,
    setRecipient,
    initialValue,
    setEmailAddress,
    isDisabled,
    field,
    dropdownModalRef,
    label,
    fromLink,
    chainId,
    required,
    placeholder,
    hasQrScanner,
    inputLeftElement,
    userSafes,
    selectableWallets,
}: RecipientFieldProps) => {
    const contactsContext = useExtendedContacts();
    const contactsReady = isContactsReady(contactsContext);
    const contactsLoading = contactsContext === 'fetching';
    const allContacts = contactsReady
        ? [
              ...contactsContext.contacts.filter(x => !selectableWallets || selectableWallets.has(x.walletAddress.toLowerCase())),
              ...((selectableWallets &&
                  [...selectableWallets]
                      .filter(x => !contactsContext.contacts.map(contact => contact.walletAddress.toLowerCase()).includes(x))
                      .map((address): Contact => ({ name: '', walletAddress: address, groups: [] }))) ??
                  []),
              ...(userSafes?.map(safe => ({ name: safe.name ?? '', walletAddress: safe.safeAddress, emailAddress: '' })) ?? []),
          ]
        : [];

    return (
        <FormControl isRequired={required} isInvalid={!!error} pos="static">
            {label && <FormLabel htmlFor={field.name}>{label}</FormLabel>}

            <DropdownSearch
                inputLeftElement={inputLeftElement}
                dropdownPortalRef={dropdownModalRef}
                isDisabled={isDisabled || !!(fromLink && initialValue && chainId)}
                allItems={allContacts}
                fieldName={field.name}
                initialValue={initialValue}
                itemToString={contact => contact.walletAddress}
                setValue={s => setRecipient(s)}
                itemToSearchableStrings={contact => [contact.name, contact.walletAddress]}
                onItemClick={contact => {
                    setRecipient(contact.walletAddress);
                    !!setEmailAddress && setEmailAddress(contact.emailAddress ?? '');
                }}
                itemToDisplayValue={contact => `${contact.name} (${shortAddress(contact.walletAddress)})`}
                emptyItem={{ name: '', walletAddress: '', groups: [] }}
                isContentLoading={contactsLoading}
                placeholder={placeholder ?? 'Wallet Address or Contact'}
                inputRightElement={
                    hasQrScanner
                        ? setValue => (
                              <QrScannerModal
                                  onSuccess={setValue}
                                  triggerElement={onOpen => (
                                      <IconButton variant="ghost" aria-label="QR code" icon={<QRCodeIcon />} onClick={onOpen} />
                                  )}
                              />
                          )
                        : undefined
                }
                fromLink={fromLink}
                chainId={chainId}
            />
            {
                <FormErrorMessage>
                    <>{error}</>
                </FormErrorMessage>
            }
        </FormControl>
    );
};

type ClaimAmountProps = BaseInputProps & {
    includeNativeToken?: boolean;
    token: TokenDto | undefined;
    amount: string;
    claimType: 'Invoice' | 'Payment';
    setAmount: (amount: string) => void;
    setToken: (token: TokenDto) => void;
    tokenMenuRef?: React.MutableRefObject<null>;
    disableErrorLabels?: boolean;
    disableBalanceLabels?: boolean;
    lockToken?: boolean;
    lockAmount?: boolean;
    networkOverride?: ChainId;
};

export const TokenConversion = ({ amount, token, ...overrides }: { amount?: number; token?: TokenDto } & StyleProps) => {
    const {
        connectedNetworkConfig: { chainId },
    } = useWeb3();
    const { getTokenPrice } = useTokenPrices();
    const { getTokenByChainIdAndAddress } = useTokenRepo();
    const tokenInfo = token && getTokenByChainIdAndAddress(chainId)(token.address);
    const isStable = tokenInfo && tokenInfo.variant === TokenVariant.STABLE;
    const tokenPrice = tokenInfo ? getTokenPrice(tokenInfo) : undefined;

    return (
        <Stack {...overrides}>
            {amount && tokenPrice && (
                <Text fontSize="sm" color="gray.400">
                    {token?.symbol && <>~ {toUSD((isStable ? 1 : tokenPrice) * amount)}</>}
                </Text>
            )}
        </Stack>
    );
};

export const claimAmountValidationSchema = Yup.string()
    .trim()
    .required('Amount is required')
    .test('is-valid-amount', 'Amount must be more than 0', value => (value ? +value > 0 : false))
    .when('token', (token: TokenDto, schema: any) =>
        schema.test({
            test: (amount: string) => {
                if (!!token && !!amount) {
                    const [, decimals] = amount.split('.');
                    const decimalCount = decimals?.length ?? 0;
                    if (decimalCount > token.decimals) return false;
                }
                return true;
            },
            message: 'Amount too small',
        }),
    );

type ChainSelectorProps = {
    chainId: ChainId;
    selectableChains: ChainId[];
    onChainSelected: (chainId: ChainId) => void;
};

export const ChainSelector = ({ chainId, selectableChains, onChainSelected, ...props }: ChainSelectorProps & Partial<ButtonProps>) => {
    return (
        <Menu>
            {({ isOpen }) => (
                <>
                    <MenuButton
                        as={Button}
                        rightIcon={<ChevronDownIcon />}
                        variant="outline"
                        borderColor="#E2E8F0"
                        focusBorderColor="rgba(138, 130, 122, 0.5)"
                        {...props}
                        _hover={{}}
                    >
                        <Text textStyle="noWrap" fontWeight={'400'}>
                            {NETWORKS[chainId].name}
                        </Text>
                    </MenuButton>
                    <MenuList display={isOpen ? 'block' : 'none'} borderRadius="4" overflowY={'auto'} maxH="400px">
                        {selectableChains.map(chain => (
                            <MenuItem key={chain} onClick={() => onChainSelected(chain)}>
                                {NETWORKS[chain].name}
                            </MenuItem>
                        ))}
                    </MenuList>
                </>
            )}
        </Menu>
    );
};

type WalletSelectorProps = {
    selectableWallets: string[];
    onWalletSelected: (wallet: string) => void;
};

export const WalletSelector = ({ selectableWallets, onWalletSelected, ...props }: WalletSelectorProps & Partial<ButtonProps>) => {
    const actingWallet = useActingWalletAddress();

    return (
        <Stack spacing="2">
            <Box fontWeight={700} fontSize="14px" lineHeight={'24px'} color="black">
                <Menu>
                    {({ isOpen }) => (
                        <>
                            <MenuButton
                                as={Button}
                                rightIcon={<ChevronDownIcon />}
                                variant="outline"
                                borderColor="#E2E8F0"
                                focusBorderColor="rgba(138, 130, 122, 0.5)"
                                {...props}
                                _hover={{}}
                            >
                                <Text textStyle="noWrap" fontWeight={'400'}>
                                    {actingWallet}
                                </Text>
                            </MenuButton>
                            <MenuList display={isOpen ? 'block' : 'none'} borderRadius="4" overflowY={'auto'} maxH="400px">
                                {selectableWallets.map(wallet => (
                                    <MenuItem key={wallet} onClick={() => onWalletSelected(wallet)}>
                                        {wallet}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </>
                    )}
                </Menu>
            </Box>
        </Stack>
    );
};

type TokenSelectorProps = {
    containerRef?: React.MutableRefObject<null>;
    selectableTokens: TokenInfo[];
    onTokenSelected: (token: TokenDto) => void;
    selectedTokenSymbol: string | undefined;
};

export const TokenSelector = ({
    containerRef,
    selectableTokens,
    onTokenSelected,
    selectedTokenSymbol,
    ...props
}: TokenSelectorProps & Partial<ButtonProps>) => {
    return (
        <Menu>
            {({ isOpen }) => (
                <>
                    <MenuButton
                        as={Button}
                        rightIcon={<ChevronDownIcon />}
                        bg="white"
                        border="1px solid"
                        borderColor={'inherit'}
                        {...props}
                    >
                        <Text textStyle="noWrap" fontWeight="500">
                            {selectedTokenSymbol ?? 'Select Token'}
                        </Text>
                    </MenuButton>
                    <Portal containerRef={containerRef}>
                        <MenuList display={isOpen ? 'block' : 'none'} borderRadius="4" overflowY={'auto'} maxH="150px">
                            {selectableTokens.map(tokenInfo => (
                                <MenuItem key={tokenInfo.token.address} onClick={() => onTokenSelected(tokenInfo.token)}>
                                    <TokenDisplay token={tokenInfo} />
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Portal>
                </>
            )}
        </Menu>
    );
};

export const ClaimAmountField = ({
    error,
    claimType,
    includeNativeToken = false,
    token,
    amount,
    field,
    setAmount,
    setToken,
    touched,
    isDisabled: transactionPending,
    required,
    label,
    tokenMenuRef,
    disableErrorLabels,
    disableBalanceLabels,
    lockToken,
    lockAmount,
    networkOverride,
}: ClaimAmountProps) => {
    const { connectedNetwork } = useWeb3();
    const { getTokenByChainIdAndAddress, tokensByChainId } = useTokenRepo();
    const chainId = networkOverride ?? connectedNetwork;
    const tokenBalances = useTokenBalances({ chainId: chainId, poll: true });
    const [max, _setMax] = useState(false);
    const tokenAddress = token?.address.toLowerCase();
    const tokenRouding = !!tokenAddress
        ? TOKEN_ROUNDING[getTokenByChainIdAndAddress(chainId)(tokenAddress)?.variant ?? TokenVariant.UNKNOWN]
        : 0;

    const _currentTokenBalance = !!tokenAddress ? tokenBalances.getBalanceForToken(tokenAddress) : undefined;

    const fetchingBalance = _currentTokenBalance == undefined;

    const currentTokenBalance = _currentTokenBalance?.toFixed(tokenRouding + 2).toString() ?? '0';
    const menuRef = useRef(null);
    const allTokens = tokensByChainId[chainId];

    const selectableTokens = useMemo(() => allTokens.filter(x => includeNativeToken || !x.token.isNative), [allTokens, includeNativeToken]);

    useEffect(() => {
        const tokenSet = new Set(selectableTokens.map(x => x.token.address.toLowerCase()));
        if (tokenAddress && !tokenSet.has(tokenAddress)) setToken(selectableTokens[0].token);
    }, [chainId, selectableTokens]);

    const setMax = () => {
        _setMax(true);
        setAmount(currentTokenBalance);
    };

    const setAmountAndResetMax = (amount: string) => {
        _setMax(false);
        setAmount(amount);
    };

    const handleAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (value.match(/^\.$/)) {
            setAmountAndResetMax(value);
        }
        if (isNumberInputValid(value)) setAmountAndResetMax(value);
    };

    const handleToken = (token: TokenDto) => {
        setToken(token);
    };

    const isRequired = required ?? true;

    const containerRef = tokenMenuRef ?? menuRef;

    return (
        <FormControl isRequired={isRequired} isInvalid={!!error && !!touched} ref={menuRef}>
            {label && (
                <FormLabelWithRequiredness isRequired={isRequired} label={label} fieldName={field.name}>
                    {!!currentTokenBalance && !disableBalanceLabels && claimType === 'Payment' && (
                        <HStack fontSize="sm" color="gray.400" position={'absolute'} right={0} pr="2" top="0">
                            <Text>Current balance:</Text>
                            <WithSkeleton isLoading={fetchingBalance} fixedWidth="50px">
                                {currentTokenBalance}
                            </WithSkeleton>
                        </HStack>
                    )}
                </FormLabelWithRequiredness>
            )}
            <InputGroup size="md">
                <InputLeftAddon px="0">
                    <TokenSelector
                        containerRef={containerRef}
                        isDisabled={transactionPending || !!lockToken}
                        onTokenSelected={handleToken}
                        selectableTokens={selectableTokens}
                        selectedTokenSymbol={token?.symbol}
                    />
                </InputLeftAddon>
                <Input
                    placeholder="0"
                    isDisabled={transactionPending || !token || lockAmount}
                    {...disabledInputProps}
                    autoComplete="password"
                    onInput={handleAmount}
                    bg="white"
                    onBlur={field.onBlur}
                    name={field.name}
                    value={!token ? '0' : max ? field.value : amount}
                />
                {claimType === 'Payment' && (
                    <>
                        <InputRightElement w="fit-content" px="2">
                            <TokenConversion amount={+amount} token={token} pr="1" />
                            <Button size="xs" onClick={setMax} mx="1" isDisabled={transactionPending || lockAmount}>
                                max
                            </Button>
                        </InputRightElement>
                    </>
                )}
            </InputGroup>
            {claimType === 'Payment'
                ? !!field.value &&
                  !isNaN(+field.value) &&
                  !!currentTokenBalance &&
                  !disableBalanceLabels && (
                      <Text fontSize="sm" color="gray.400" position={'absolute'} right={0} pr="2">
                          Projected balance: {(+currentTokenBalance - +field.value).toFixed(tokenRouding) ?? 0}
                      </Text>
                  )
                : !!label && <TokenConversion amount={+amount} token={token} right="0" pr="2" pos="absolute" />}
            {touched && !disableErrorLabels && (
                <FormErrorMessage>
                    <>{error}</>
                </FormErrorMessage>
            )}
        </FormControl>
    );
};

const fileOptions = ['generate', 'upload'] as const;
type FileOption = typeof fileOptions[number];

export const PreviewGeneratedAttachmentLink = ({ onClick }: { onClick: VoidFunction }) => (
    <>
        <HStack spacing="0">
            <Text>{'('}</Text>
            <Text color={'gray.600'}>Preview</Text>
            <IconButton mx="0" aria-label="link" icon={<ExternalLinkIcon />} fontSize="sm" variant={'ghost'} onClick={onClick} />
            <Text>{')'}</Text>
        </HStack>
    </>
);

type BullaFileUploadProps = {
    attachment: BullaFileObject | UploadedFile;
    setAttachment: (attachment: BullaFileObject | UploadedFile) => void;
    isDisabled: boolean;
    uploadToIpfsOnAccept: boolean;
    setErrorOn: VoidFunction;
    setErrorOff: VoidFunction;
    required?: boolean;
    renderUploadSection?: (isDisabled: boolean, isLoading: boolean) => React.ReactNode;
    isLoading?: boolean;
};

export const BullaFileUpload = ({
    attachment,
    setAttachment,
    isDisabled,
    uploadToIpfsOnAccept,
    setErrorOff,
    setErrorOn,
    renderUploadSection,
    isLoading,
}: BullaFileUploadProps) => {
    return 'file' in attachment ? (
        <BullaAttachmentInput
            attachment={attachment}
            setAttachment={setAttachment}
            isDisabled={isDisabled}
            onError={setErrorOn}
            onAccept={setErrorOff}
            uploadToIpfsOnAccept={uploadToIpfsOnAccept}
            renderUploadSection={renderUploadSection ? () => renderUploadSection(isDisabled, isLoading ?? false) : undefined}
        />
    ) : 'ipfsHash' in attachment ? (
        <HStack>
            <FileNameLabel filename={attachment.fileName ?? 'Uploaded file'} />
            <IconButton
                mx="0"
                aria-label="delete"
                onClick={() => setAttachment({ file: 'not-uploaded' })}
                icon={<Image src={DeleteIcon} color="gray.800" />}
                colorScheme="gray"
                fontSize="sm"
            />
            <IconButton
                mx="0"
                aria-label="open file"
                onClick={() => openIpfsLink(attachment.ipfsHash)}
                icon={<ExternalLinkIcon />}
                colorScheme="gray"
                fontSize="sm"
            />
        </HStack>
    ) : null;
};

export const FileUploadField = ({
    error,
    attachment,
    setAttachment,
    uploadToIpfsOnAccept,
    setErrorOff,
    setErrorOn,
    label,
    field,
    isDisabled: transactionPending,
    required,
    renderUploadSection,
    isLoading,
}: Omit<BullaFileUploadProps, 'disabled'> & BaseInputProps) => (
    <FormControl isInvalid={!!error}>
        {label && <FormLabelWithRequiredness fieldName={field.name} isRequired={required ?? false} label={label} />}
        {!!error && <FormErrorMessage>Unexpected error uploading file.</FormErrorMessage>}
        <BullaFileUpload
            attachment={attachment}
            setAttachment={setAttachment}
            isDisabled={transactionPending}
            setErrorOn={setErrorOn}
            setErrorOff={setErrorOff}
            uploadToIpfsOnAccept={!!uploadToIpfsOnAccept}
            renderUploadSection={renderUploadSection ? () => renderUploadSection(transactionPending, isLoading ?? false) : undefined}
        />
    </FormControl>
);

export const AttachmentField = ({
    attachment,
    setAttachment,
    transactionPending,
    field,
    label,
    type,
    amount,
    description,
    recipient,
    tokenSymbol,
    dueDate,
    uploadToIpfsOnAccept,
}: {
    attachment?: BullaItemAttachment;
    setAttachment: (attachment: BullaItemAttachment) => void;
    transactionPending: boolean;
    label?: string;
    field: FormiklessField | FieldInputProps<any>;
    type: ClaimType;
    recipient: EthAddress;
    amount: string; // parsed amount string in denominated token
    tokenSymbol: string;
    description: string;
    dueDate?: Date;
    uploadToIpfsOnAccept?: boolean;
}) => {
    const { getAttachmentGenerationLink } = useCompanyDetailsRepo();
    const { user } = useAuth();
    const { attachmentGenerationState, setAttachmentGenerationState, getCompanyDetails } = useAttachmentGenerationState();

    const requiredItemDetailsReady = (!!recipient || type == 'Invoice') && !!+amount;
    const generateLabel = type === 'Invoice' ? 'Generate invoice' : 'Generate payment receipt';
    const attachmentLink = getAttachmentGenerationLink(type, recipient, amount, tokenSymbol, description);

    const [error, { on: setErrorOn, off: setErrorOff }] = useBoolean();
    const option = attachment === 'generate' ? 'generate' : attachment === undefined ? undefined : 'upload';

    const handleSetOption = (option: FileOption) => {
        if (option === 'generate') setAttachment(option);
        else if (option === 'upload') setAttachment({ file: 'not-uploaded' });
    };

    const viewFile = () => window.open(attachmentLink, '_blank');
    return (
        <FormControl isInvalid={error}>
            {label && <FormLabel htmlFor={field.name}>{label}</FormLabel>}
            {error && <FormErrorMessage>Unexpected error uploading file.</FormErrorMessage>}
            <RadioGroup onChange={opt => handleSetOption(opt as FileOption)} value={option}>
                <Stack spacing="1">
                    <HStack spacing={2}>
                        <Radio
                            value={fileOptions[0]}
                            colorScheme={'brand'}
                            isDisabled={!type || attachmentGenerationState !== 'ready' || !isUserReady(user) || transactionPending}
                        >
                            {generateLabel}
                        </Radio>
                        {attachmentGenerationState !== 'ready' ? (
                            <HStack>
                                <ComapanyDetailsFromClaimCreationModal
                                    onSignIn={async () => {
                                        const details = await getCompanyDetails();
                                        if (details) {
                                            handleSetOption('generate');
                                            setAttachmentGenerationState('ready');
                                        } else {
                                            setAttachmentGenerationState('no-details');
                                        }
                                    }}
                                    fetchingDetails={attachmentGenerationState === 'fetching'}
                                    onDetailsReady={() => setAttachmentGenerationState('ready')}
                                    triggerElement={onOpen => (
                                        <TextButton
                                            onClick={onOpen}
                                            color="brand.bulla_blue"
                                            fontWeight={600}
                                            isDisabled={transactionPending}
                                        >
                                            {attachmentGenerationState == 'unauthenticated'
                                                ? 'Sign In'
                                                : attachmentGenerationState == 'no-details'
                                                ? 'Add Company Details'
                                                : ''}
                                        </TextButton>
                                    )}
                                />
                            </HStack>
                        ) : option == 'generate' ? (
                            <Fade in={requiredItemDetailsReady} unmountOnExit>
                                <PreviewGeneratedAttachmentLink onClick={viewFile} />
                            </Fade>
                        ) : null}
                    </HStack>

                    <Radio value={fileOptions[1]} colorScheme={'brand'} isDisabled={transactionPending}>
                        Upload a file
                    </Radio>
                    {attachment !== undefined && attachment !== 'generate' && (
                        <BullaFileUpload
                            attachment={attachment}
                            setAttachment={setAttachment}
                            isDisabled={transactionPending}
                            setErrorOn={setErrorOn}
                            setErrorOff={setErrorOff}
                            uploadToIpfsOnAccept={!!uploadToIpfsOnAccept}
                        />
                    )}
                </Stack>
            </RadioGroup>
        </FormControl>
    );
};

type ClaimDescriptionFieldProps = BaseInputProps & { disableErrorLabels?: boolean; isDisabled?: boolean; fromLink?: boolean };
export const disabledInputProps = { _disabled: { bg: 'gray.100', color: 'black.700', cursor: 'not-allowed' }, _hover: {} };

export const ClaimDescriptionField = ({
    error,
    touched,
    field,
    isDisabled: transactionPending,
    required,
    label,
    disableErrorLabels,
    isDisabled,
    fromLink,
}: ClaimDescriptionFieldProps) => {
    const isRequired = required ?? true;
    return (
        <FormControl isRequired={isRequired} isInvalid={!!error && !!touched}>
            {label && <FormLabelWithRequiredness fieldName={field.name} isRequired={isRequired} label={label} fromLink={fromLink} />}
            <InputGroup>
                <Input
                    placeholder="Enter description"
                    isDisabled={transactionPending || isDisabled}
                    {...disabledInputProps}
                    autoComplete="off"
                    {...field}
                    bg="white"
                />
            </InputGroup>
            {touched && !disableErrorLabels && (
                <FormErrorMessage>
                    <>{error}</>
                </FormErrorMessage>
            )}
        </FormControl>
    );
};

type BasicWalletAddressFieldProps = BaseInputProps & { disableErrorLabels?: boolean; isDisabled?: boolean };
export const BasicWalletAddressField = ({
    error,
    touched,
    field,
    isDisabled: transactionPending,
    required,
    label,
    disableErrorLabels,
    isDisabled,
}: BasicWalletAddressFieldProps) => {
    const isRequired = required ?? true;
    return (
        <FormControl isRequired={isRequired} isInvalid={!!error && !!touched}>
            {label && <FormLabelWithRequiredness fieldName={field.name} isRequired={isRequired} label={label} />}
            <InputGroup>
                <Input
                    placeholder="Enter wallet address"
                    isDisabled={transactionPending || isDisabled}
                    {...disabledInputProps}
                    autoComplete="off"
                    {...field}
                    bg="white"
                />
            </InputGroup>
            {touched && !disableErrorLabels && (
                <FormErrorMessage>
                    <>{error}</>
                </FormErrorMessage>
            )}
        </FormControl>
    );
};

type ClaimNotesFieldProps = BaseInputProps & { disableErrorLabels?: boolean };

export const ClaimNotesField = ({
    error,
    touched,
    field,
    isDisabled: transactionPending,
    label,
    disableErrorLabels,
}: ClaimNotesFieldProps) => {
    const premiumMembership = useMembership();
    const userIsPremium = !!premiumMembership;
    const [modalOpen, setModalOpen] = useState(false);
    const closeModal = () => setModalOpen(false);

    return (
        <FormControl isRequired={false} isInvalid={!!error && !!touched}>
            <PremiumPricingModal modalOpen={modalOpen} closeModal={closeModal} />

            {label && (
                <Flex alignItems="baseline">
                    <FormLabelWithRequiredness fieldName={field.name} isRequired={false} label={label} />
                    {!userIsPremium && (
                        <BullaBlueTextButton
                            onClick={() => {
                                setModalOpen(true);
                            }}
                            textDecor="none"
                            fontWeight={700}
                            fontSize="14px"
                            lineHeight={'24px'}
                            ml={-2}
                        >
                            Unlock
                        </BullaBlueTextButton>
                    )}
                </Flex>
            )}
            <InputGroup>
                <Input
                    placeholder="Enter Notes"
                    isDisabled={transactionPending || !userIsPremium}
                    autoComplete="off"
                    {...field}
                    bg="white"
                />
            </InputGroup>
            {touched && !disableErrorLabels && (
                <FormErrorMessage>
                    <>{error}</>
                </FormErrorMessage>
            )}
        </FormControl>
    );
};

export const NameField = ({ error, touched, field, isDisabled: transactionPending, required, label }: BaseInputProps) => {
    const isRequired = required ?? true;
    return (
        <FormControl isRequired={isRequired} isInvalid={!!error && !!touched}>
            {label && <FormLabelWithRequiredness fieldName={field.name} isRequired={isRequired} label={label} />}
            <InputGroup>
                <Input placeholder="Name" isDisabled={transactionPending} autoComplete="off" {...field} />
            </InputGroup>
        </FormControl>
    );
};

export const WalletAddressField = ({ error, touched, field, isDisabled: transactionPending, required, label }: BaseInputProps) => {
    const isRequired = required ?? true;
    return (
        <FormControl isRequired={isRequired} isInvalid={!!error && !!touched}>
            {label && <FormLabelWithRequiredness fieldName={field.name} isRequired={isRequired} label={label} />}
            <InputGroup>
                <Input placeholder="Wallet address" isDisabled={transactionPending} autoComplete="off" {...field} />
            </InputGroup>
        </FormControl>
    );
};

type DueByFieldProps = BaseInputProps & {
    setDueBy: (date: Date) => void;
    withComponent?: React.ReactNode;
    placement?: string;
};

export const DueByField = ({ error, field, setDueBy, touched, isDisabled, label, required, placement }: DueByFieldProps) => {
    const isRequired = required ?? true;

    return (
        <FormControl isRequired={isRequired} isInvalid={!!error}>
            {label && <FormLabelWithRequiredness fieldName={field.name} isRequired={isRequired} label={label} />}
            <Flex direction="row" alignItems="center">
                <InputGroup maxW="fit-content" isolation="auto">
                    <DatePicker
                        disabled={!!isDisabled}
                        selectedDate={field.value}
                        onChange={setDueBy}
                        minDate={moment().add(1, 'day').toDate()}
                        dateFormat="dd MMM yyyy"
                        placement={placement}
                        portalId="root-portal"
                    />
                </InputGroup>
                <Text fontWeight={'500'} ml={2}>
                    {`(Due ${calculateDateDifference(field.value)})`}
                </Text>
            </Flex>

            <FormErrorMessage>
                <>{error}</>
            </FormErrorMessage>
        </FormControl>
    );
};

type AccountTagFieldProps = BaseInputProps & {
    setTags: (tags: string[]) => void;
    setStatus: (status?: any) => void;
    creatingExpense: boolean;
    dropdownModalRef?: React.RefObject<HTMLDivElement>;
};
export const AccountTagField = ({
    error,
    field,
    setTags,
    isDisabled: transactionPending,
    setStatus,
    label,
    dropdownModalRef,
    creatingExpense,
    ...props
}: AccountTagFieldProps & Partial<StackProps>): JSX.Element => {
    const tags = field.value as string[];
    const addTag = (newTag: string) => setTags(newTag == 'Expense' || newTag == 'Rental' ? [newTag, ...tags] : [...tags, newTag]);
    const removeTag = (newTag: string) => setTags(tags.filter(tag => tag.toLowerCase() !== newTag.toLowerCase()));
    return (
        <FormControl isInvalid={!!error}>
            {label && <FormLabelWithRequiredness fieldName={field.name} isRequired={false} label={label} />}
            <AccountTagViewer
                tags={tags}
                removeTag={removeTag}
                addTag={addTag}
                creatingExpense={creatingExpense}
                setEditing={{
                    on: () => setStatus('editing'),
                    off: () => setStatus('ready'),
                }}
                isDisabled={transactionPending}
                fieldName={field.name}
                editState={'ready'}
                dropdownPortalRef={dropdownModalRef}
                {...props}
            />
        </FormControl>
    );
};

type EmailFieldProps = BaseInputProps & {
    disableErrorLabels?: boolean;
    required?: boolean;
};

export const EmailField = ({
    error,
    touched,
    isDisabled: transactionPending,
    field,
    label,
    disableErrorLabels,
    required,
}: EmailFieldProps) => {
    const isRequired = required ?? true;

    return (
        <FormControl isInvalid={!!error && !!touched} isRequired={isRequired}>
            {label && <FormLabelWithRequiredness fieldName={field.name} isRequired={isRequired} label={label} />}
            <InputGroup>
                <Input isDisabled={transactionPending} placeholder="email@example.com" {...field} bg="white" {...disabledInputProps} />
            </InputGroup>
            {touched && !disableErrorLabels && (
                <FormErrorMessage>
                    <>{error}</>
                </FormErrorMessage>
            )}
        </FormControl>
    );
};

type LockedInTokenAmountFieldProps = Omit<BaseInputProps, 'isDisabled'> & {
    symbol: string;
    setAmount: (amount: string) => void;
    editable?: boolean;
};
export const LockedInTokenAmountField = ({ field, label, editable, symbol, setAmount }: LockedInTokenAmountFieldProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [canEdit, setCanEdit] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (isNumberInputValid(value)) setAmount(value);
    };

    const handleEditToggle = () => {
        setCanEdit(canEdit => {
            const nextEditState = !canEdit;
            if (nextEditState)
                setTimeout(() => {
                    inputRef.current?.focus();
                }, 50);
            return nextEditState;
        });
    };

    return (
        <FormControl isInvalid={false}>
            {label && <FormLabelWithRequiredness fieldName={field.name} isRequired={true} label={label} />}
            <InputGroup>
                <Input
                    isDisabled={!canEdit}
                    autoComplete="off"
                    name={field.name}
                    onBlur={field.onBlur}
                    value={!canEdit ? `${field.value ? field.value : 0} ${symbol}` : field.value}
                    onChange={handleChange}
                    bg={canEdit ? 'initial' : '#E2E8F0'}
                    color={'black'}
                    ref={inputRef}
                    {...disabledInputProps}
                />
                {editable && (
                    <InputRightElement>
                        <BullaBlueTextButton onClick={handleEditToggle} mr="4">
                            {canEdit ? 'Done' : 'Edit'}
                        </BullaBlueTextButton>
                    </InputRightElement>
                )}
            </InputGroup>
        </FormControl>
    );
};

type PercentAmountFieldProps = Omit<BaseInputProps, 'isDisabled'> & {
    setAmount: (amount: string) => void;
    inputRightElement?: React.ReactNode;
    disabled?: boolean;
};

export const PercentAmountField = ({ field, label, setAmount, touched, error, inputRightElement, disabled }: PercentAmountFieldProps) => {
    const handleAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const validBPS = new RegExp(/^[0-9]*.?[0-9]{0,2}$/);
        if (isNumberInputValid(value) && validBPS.test(value)) setAmount(value);
    };

    return (
        <FormControl isInvalid={!!error && !!touched}>
            {label && <FormLabelWithRequiredness fieldName={field.name} isRequired={true} label={label} />}
            <InputGroup size="md">
                <Input
                    placeholder="0%"
                    autoComplete="password"
                    onInput={handleAmount}
                    bg="white"
                    onBlur={field.onBlur}
                    name={field.name}
                    value={field.value}
                    isDisabled={!!disabled}
                    {...disabledInputProps}
                />
                {inputRightElement && (
                    <InputRightElement w="fit-content" px="2">
                        {inputRightElement}
                    </InputRightElement>
                )}
            </InputGroup>
            {touched && (
                <FormErrorMessage>
                    <>{error}</>
                </FormErrorMessage>
            )}
        </FormControl>
    );
};
