import { WarningTwoIcon } from '@chakra-ui/icons';
import { Box, Button, Checkbox, Container, Grid, GridItem, Heading, HStack, Spacer, Stack, Text, Tooltip } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { WarningIconWithTooltip } from '../../../assets/warning';
import { ClaimType } from '../../../data-lib/data-model';
import { addDaysToToday } from '../../../data-lib/helpers';
import { BULLA_COLLECTION_ADDRESS, instantPaymentGasCost, TokenInfo } from '../../../data-lib/networks';
import { requireBatchCreate, useBullaBanker } from '../../../hooks/useBullaBanker';
import { useTokenBalances, useTokenPrices } from '../../../hooks/useChainData';
import { useSendClaimEmail } from '../../../hooks/useEmail';
import { useInstantPayment } from '../../../hooks/useInstantPayment';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { useWeb3 } from '../../../hooks/useWeb3';
import { isUserReady, useAuth } from '../../../state/auth-state';
import { toUSD } from '../../../tools/common';
import { InstantPaymentButton } from '../../display/claim-action-buttons';
import { ResponsiveStack } from '../../display/responsive-stack';
import { CreateOrangeButton, CreateSmallWhiteButton } from '../../inputs/buttons';
import { ShadowBox } from '../../layout/cards';
import { CreateClaimFields } from '../create-claim-modal/create-claim-modal';
import { BullaFeeRow, ReadOnlyBullaItemRow } from './batch-rows';
import { BatchWizardState, CompleteDetails, detailsWithDefaultValues, InvoiceDetails, PaymentDetails, ReviewState } from './batch-state';
import { BatchFooter } from './shared-components';
import { ContactsRepo, isContactsReady, useExtendedContacts } from '../../../hooks/useExtendedContacts';
import { useTokenRepo } from '../../../hooks/useTokenRepo';
import { useTokenSafety } from '../../../hooks/useTokenSafety';

const USD_FEE_PER_RECIPIENT = 2;
const MINIMUM_THRESHOLD = 100;

const toCreateClaimParams = (claim: CompleteDetails<PaymentDetails> & { dueDate?: Date }, isClaim: boolean): CreateClaimFields => ({
    ...claim,
    instantPayment: !isClaim,
    token: claim.token!,
    recipient: claim.walletAddress,
    claimAmount: claim.amount,
    tags: claim.tags,
    dueBy: claim.dueDate!,
});

const TokenCannotBePricedIcon = () => (
    <Tooltip label={'This token cannot be priced. To price this token, contact us on Discord.'}>
        <WarningTwoIcon color={'#EDA321'} w="1.2em" h="1.2em" />
    </Tooltip>
);

const SomeTokensCouldNotBePricedIcon = () => (
    <Tooltip label={'Some tokens could not be priced.'}>
        <WarningTwoIcon color={'#EDA321'} w="1.2em" h="1.2em" />
    </Tooltip>
);

type OpenedReviewCardProps = {
    state: ReviewState;
    claimType: ClaimType;
    onWizardComplete: VoidFunction;
    setWizardState: React.Dispatch<React.SetStateAction<BatchWizardState>>;
    claimConfigurationOpenCloseDetailsOverride: 'Open' | 'Close';
    toggleClaimConfigurationOpenCloseDetails: VoidFunction;
};

export const OpenedReviewCard = ({
    state,
    claimType,
    onWizardComplete,
    setWizardState,
    claimConfigurationOpenCloseDetailsOverride,
    toggleClaimConfigurationOpenCloseDetails,
}: OpenedReviewCardProps) => {
    const {
        connectedNetwork,
        connectedNetworkConfig: {
            gasLimitPerTransaction,
            batchCreate: { maxClaims },
        },
    } = useWeb3();
    const [executingClaims, { batchCreate }] = useBullaBanker();
    const { getTokenPrice } = useTokenPrices();
    const [executingIP, { createInstantPayments }] = useInstantPayment();
    const { authenticate, user } = useAuth();
    const sendClaimCreatedEmail = useSendClaimEmail();
    const contactsContext = useExtendedContacts();
    const {
        connectedNetworkConfig: { nativeCurrency },
    } = useWeb3();
    const tokenBalances = useTokenBalances({ chainId: connectedNetwork });
    const actingWallet = useActingWalletAddress();
    const { tokensByChainId, getTokenByChainIdAndAddress, resolveTokenInfo } = useTokenRepo();
    const { isTokenInfoSafe } = useTokenSafety();

    const isClaim = !!state.defaultValues.defaultDueDate;

    const connectedNetworkTokens = tokensByChainId[connectedNetwork];
    const initialERC20 = connectedNetworkTokens.filter(x => !x.token.isNative)[0].token;
    const initialToken = isClaim ? initialERC20 : nativeCurrency.tokenInfo.token;

    const [feeToken, setFeeToken] = React.useState(initialToken);

    const paymentDateColumnSize = isClaim ? '3fr ' : '';
    const claimGridColumns = `4fr 8fr ${paymentDateColumnSize}3fr 3fr 2fr`;
    const columns = ['NAME', 'WALLET ADDRESS', isClaim ? 'PAYMENT DATE' : undefined, 'AMOUNT', 'DESCRIPTION', ''].filter(
        (x): x is string => x !== undefined,
    );

    const fillWithDefaultValues = detailsWithDefaultValues(state.defaultValues);
    const values = state.claimDetails.map(fillWithDefaultValues);

    const setOfTokenAddresses = new Set(values.map(x => x.token.address.toLowerCase()));

    useEffect(
        // extra precaution for tokens coming from an old batch draft
        () => [...setOfTokenAddresses].forEach(tokenAddress => resolveTokenInfo(connectedNetwork, tokenAddress)),
        [setOfTokenAddresses],
    );

    const executing = executingClaims || executingIP || user == 'authenticating';

    const batchSize = isClaim ? maxClaims : Math.floor(gasLimitPerTransaction / instantPaymentGasCost);
    const numberOfBatches = Math.ceil((state.claimDetails.length + 1) / batchSize);
    const needsSignIn = useMemo(() => values.some(value => value.attachment === 'generate') && !isUserReady(user), [values, user]);

    const getConnectedChainTokenByAddress = getTokenByChainIdAndAddress(connectedNetwork);

    const tokenInfoByTokenAddress = values
        .map(({ token }) => getConnectedChainTokenByAddress(token.address))
        .filter((x): x is TokenInfo => x !== undefined)
        .reduce<Record<string, TokenInfo>>((acc, item) => ({ ...acc, [item.token.address.toLowerCase()]: item }), {});

    const amountsByTokenAddress = values.reduce<Record<string, { tokenInfo: TokenInfo | undefined; amount: number }>>(
        (acc, item) => ({
            ...acc,
            [item.token.address.toLowerCase()]: {
                amount: (acc[item.token.address.toLowerCase()]?.amount ?? 0) + +item.amount,
                tokenInfo: tokenInfoByTokenAddress[item.token.address.toLowerCase()],
            },
        }),
        {},
    );

    const usdAmountsByRecipientAddress = values.reduce<Record<string, number>>(
        (acc, item) => ({
            ...acc,
            [item.walletAddress.toLowerCase()]: Math.max(
                ((tokenInfoByTokenAddress[item.token.address.toLowerCase()] &&
                    getTokenPrice(tokenInfoByTokenAddress[item.token.address.toLowerCase()])) ||
                    0) *
                    +item.amount +
                    (acc[item.walletAddress.toLowerCase()] ?? 0),
                USD_FEE_PER_RECIPIENT,
            ),
        }),
        {},
    );

    const bullaFeeUSD = Object.values(usdAmountsByRecipientAddress)
        .map((x): number => (x >= MINIMUM_THRESHOLD ? USD_FEE_PER_RECIPIENT : 0))
        .reduce((a, b) => a + b, 0);

    const bullaFeeInToken = !getTokenPrice
        ? '0'
        : (() => {
              const tokenInfo = getConnectedChainTokenByAddress(feeToken.address);
              if (!tokenInfo) return '0';
              const tokenPrice = getTokenPrice(tokenInfo);
              return tokenPrice ? (bullaFeeUSD / tokenPrice).toPrecision(3) : '0';
          })();

    const showTokenCannotBePrice = Object.entries(tokenInfoByTokenAddress).some(
        ([_, tokenInfo]) => tokenInfo === undefined || !getTokenPrice(tokenInfo),
    );

    const usdValuesByCategories = Object.entries(
        values.reduce<Record<string, number>>((acc, { amount, tags: _tag, token }) => {
            const tokenInfo = tokenInfoByTokenAddress[token.address.toLowerCase()];
            const tags = _tag.length > 0 ? _tag : ['Uncategorized'];

            return tags.reduce<Record<string, number>>(
                (acc, tag) => ({ ...acc, [tag]: (acc[tag] ?? 0) + ((tokenInfo && getTokenPrice(tokenInfo)) || 0) * +amount }),
                acc,
            );
        }, {}),
    ).sort(([_, a], [__, b]) => b - a);

    const usdValuesNumbersByToken = Object.entries(amountsByTokenAddress).map(([address, { amount, tokenInfo }]) => [
        address,
        ((tokenInfo && getTokenPrice(tokenInfo)) || 0) * amount,
    ]);

    const usdValueOfTransaction = `${toUSD(
        usdValuesNumbersByToken.reduce<number>((acc, [_, amount]) => acc + +amount, 0) + bullaFeeUSD,
    )} USD`;
    const usdValueStringsByAddress = usdValuesNumbersByToken.reduce<Record<string, string | undefined>>(
        (acc, [address, usdValue]) => ({
            ...acc,
            [address]: +usdValue !== 0 ? toUSD(+usdValue) : undefined,
        }),
        {},
    );

    const addBullaFee = bullaFeeUSD != 0;
    const batches: (CompleteDetails<PaymentDetails> | 'fee')[][] | (CompleteDetails<InvoiceDetails> | 'fee')[][] = [
        ...new Array(numberOfBatches).keys(),
    ].map((_, i) => [...values, ...(addBullaFee ? ['fee' as const] : [])].slice(i * batchSize, (i + 1) * batchSize));

    const bullaFeeClaimParams: CreateClaimFields = {
        claimAmount: bullaFeeInToken,
        description: 'Bulla Batch Fee',
        dueBy: addDaysToToday(30),
        instantPayment: !isClaim,
        recipient: BULLA_COLLECTION_ADDRESS,
        tags: ['Expense', 'Bulla Fees'],
        token: feeToken,
        customCreditorAndDebtor: { creditor: BULLA_COLLECTION_ADDRESS, debtor: actingWallet },
    };

    const handleCreate = async (claimsInBatch: ((CompleteDetails<PaymentDetails> & { dueDate?: Date | undefined }) | 'fee')[]) => {
        if (needsSignIn) await authenticate();
        const claimsParamsToCreate = claimsInBatch.map(
            (input): CreateClaimFields => (input == 'fee' ? bullaFeeClaimParams : toCreateClaimParams(input, isClaim)),
        );

        const createFn = isClaim
            ? () => {
                  requireBatchCreate(batchCreate);
                  return batchCreate.createClaimsBatch(claimType, claimsParamsToCreate);
              }
            : () => {
                  const inputs: CreateClaimFields[] = claimsParamsToCreate.map(params => ({
                      ...params,
                      tags: params.tags ?? [],
                      instantPayment: true,
                  }));
                  return createInstantPayments(inputs);
              };

        return createFn().then(tx => {
            if (tx) {
                try {
                    isContactsReady(contactsContext) && state.saveToContacts && contactsToBeAdded(contactsContext).length > 0
                        ? contactsContext.addOrUpdateContactsAsync(
                              contactsToBeAdded(contactsContext).map(x => ({
                                  name: x.name,
                                  walletAddress: x.walletAddress,
                                  emailAddress: x.emailAddress.trim() === '' ? undefined : x.emailAddress,
                                  groups: [],
                              })),
                          )
                        : {};
                    sendClaimCreatedEmail(
                        claimType,
                        claimsInBatch
                            .filter((x): x is CompleteDetails<PaymentDetails> & { dueDate?: Date | undefined } => x !== 'fee')
                            .map(
                                ({
                                    amount,
                                    token,
                                    walletAddress: recipient,
                                    description,
                                    emailAddress,
                                    emailMessage,
                                    dueDate,
                                    confirmationEmailAddress,
                                }) => ({
                                    token: token!,
                                    claimAmount: amount,
                                    recipient,
                                    description,
                                    emailAddress: emailAddress,
                                    emailMessage: emailMessage,
                                    dueBy: dueDate ?? new Date(),
                                    emailCC: confirmationEmailAddress,
                                }),
                            ),
                        tx,
                    );
                } catch (e) {
                    console.error('cant send email', e);
                }

                return state.batchIndex + 1 == numberOfBatches
                    ? onWizardComplete()
                    : setWizardState({ ...state, batchIndex: state.batchIndex + 1 });
            }
        });
    };

    const contactsToBeAdded = (contactsContext: ContactsRepo) =>
        state.claimDetails
            .filter(x => contactsContext.getContact(x.walletAddress) === 'not-found' && x.name.trim() !== '')
            .reduce<PaymentDetails[]>(
                (acc, item) => (acc.map(x => x.walletAddress).includes(item.walletAddress) ? acc : [...acc, item]),
                [],
            );

    const selectableTokens = React.useMemo(
        () => tokensByChainId[connectedNetwork].filter(x => (!isClaim || !x.token.isNative) && isTokenInfoSafe(x)),
        [tokensByChainId[connectedNetwork], isTokenInfoSafe],
    );

    return (
        <Container maxW="container.xl" display="flex" flexDir="column" p="0">
            <ShadowBox p="4" mb="8" pb="4" display={'flex'} flexDir="column" key="shadow box">
                <HStack p="4" key="header">
                    <Box p="0">
                        <Heading color="heading" my="auto" fontSize={18}>
                            Review
                        </Heading>
                        {batches.length !== 1 && (
                            <Text color="heading" mt="1" fontSize={'14px'} fontWeight="500">
                                {`Batch split in ${batches.length} transactions`}
                            </Text>
                        )}
                    </Box>
                    <Spacer />
                    <HStack mt="0">
                        {CreateSmallWhiteButton(`${claimConfigurationOpenCloseDetailsOverride == 'Open' ? 'Close' : 'Open'} all details`)({
                            isDisabled: false,
                            onClick: toggleClaimConfigurationOpenCloseDetails,
                        })}
                    </HStack>
                </HStack>

                <ResponsiveStack px="4" alignItems={'flex-start'} py="2" spacing={16} key="recap">
                    <Stack spacing={1} w="fit-content" key="amount">
                        <HStack>
                            <Text fontWeight={700} fontSize={'1.2em'} color="#353739">
                                Total Amount
                            </Text>
                            {showTokenCannotBePrice && <SomeTokensCouldNotBePricedIcon />}
                        </HStack>

                        <Text fontWeight={500} fontSize={'2.3em'} color="#353739" whiteSpace={'nowrap'}>
                            {usdValueOfTransaction}
                        </Text>
                    </Stack>
                    <Stack spacing={0.5} alignItems={'flex-start'} w="fit-content" key="asset">
                        <Text fontWeight={700} fontSize={'1.2em'} color="#353739" pb="1" whiteSpace={'nowrap'}>
                            Asset Breakdown
                        </Text>
                        {Object.entries(amountsByTokenAddress)
                            .sort((a, b) => b[1].amount - a[1].amount)
                            .map(amountByTokenAddress => {
                                const address = amountByTokenAddress[0];
                                const usdValue = usdValueStringsByAddress[address];
                                const tokenBalance = tokenBalances.getBalanceForToken(address) ?? 0;
                                const amount = amountByTokenAddress[1].amount;

                                const insufficientFunds = tokenBalance < amount && !isClaim;
                                const color = insufficientFunds ? '#C53030' : '#353739';

                                return (
                                    <Box key={address}>
                                        <HStack>
                                            <Text fontWeight={400} fontSize={'1.2em'} color={color}>
                                                {amount}
                                            </Text>
                                            <Text fontWeight={700} fontSize={'1.2em'} color={color}>
                                                {getConnectedChainTokenByAddress(amountByTokenAddress[0])?.token.symbol ?? '---'}
                                            </Text>
                                            {insufficientFunds && (
                                                <WarningIconWithTooltip
                                                    label={`Insufficient funds. Current balance: ${tokenBalance} ${address}`}
                                                    warningOverrides={{ color, w: '1.2em', h: '1.2em' }}
                                                />
                                            )}
                                            {usdValue === undefined && <TokenCannotBePricedIcon />}
                                        </HStack>
                                        {usdValue !== undefined && (
                                            <Text fontWeight={500} fontSize={'0.9em'} color="#818181">
                                                {usdValue} USD
                                            </Text>
                                        )}
                                    </Box>
                                );
                            })}
                    </Stack>
                    <Stack spacing={1} alignItems={'flex-start'} w="fit-content" key="transfers">
                        <Text fontWeight={700} fontSize={'1.2em'} color="#353739" whiteSpace={'nowrap'} key="total transfers">
                            Total Transfers
                        </Text>
                        <Text fontWeight={500} fontSize={'2.3em'} color="#353739" key="total transfers val">
                            {values.length}
                        </Text>
                        <Text fontWeight={700} fontSize={'1.2em'} color="#353739" pt="2" whiteSpace={'nowrap'} key="total wallets">
                            Total Wallets
                        </Text>
                        <Text fontWeight={500} fontSize={'2.3em'} color="#353739" key="total wallets val">
                            {[...new Set(values.map(x => x.walletAddress.toLowerCase()))].length}
                        </Text>
                    </Stack>
                    <Box flex="1" minWidth={'350px'} key="categories">
                        <HStack>
                            <Text fontWeight={700} fontSize={'1.2em'} color="#353739">
                                Categories
                            </Text>
                            {showTokenCannotBePrice && <SomeTokensCouldNotBePricedIcon />}
                        </HStack>

                        <ReactApexChart
                            options={{
                                chart: { toolbar: { show: false }, foreColor: '#A3AED0', fontFamily: 'Inter' },
                                xaxis: {
                                    categories: usdValuesByCategories.map(([category]) => category),
                                    labels: {
                                        formatter: stringValue => {
                                            const value = +stringValue;
                                            const useK = value >= 1000;
                                            const usdValue = toUSD(useK ? value / 1000 : value);
                                            return useK ? usdValue.substring(0, usdValue.length - 3) + 'K' : usdValue;
                                        },
                                    },
                                    position: 'top',
                                },
                                yaxis: {},
                                plotOptions: {
                                    bar: {
                                        horizontal: true,
                                        borderRadius: 8,
                                        barHeight: '29%',
                                    },
                                },
                                dataLabels: { enabled: false },
                                tooltip: { y: { formatter: stringValue => toUSD(+stringValue) } },
                                grid: {
                                    xaxis: { lines: { show: true } },
                                    yaxis: { lines: { show: false } },
                                },
                            }}
                            series={[
                                {
                                    data: usdValuesByCategories.map(([_, usdValue]) => usdValue),
                                    name: 'Amount ($USD)',
                                    color: '#E66C0A',
                                },
                            ]}
                            type={'bar'}
                            height="auto"
                        />
                    </Box>
                </ResponsiveStack>

                <Stack p="0" w="100%" key="rows" fontWeight={500}>
                    {batches.map((values, batchI) => (
                        <Box pt={4} key={batchI}>
                            {batches.length !== 1 && (
                                <Container px="4" py="2" w="fit-content">
                                    <Text>Transaction {batchI + 1}</Text>
                                </Container>
                            )}
                            <Grid key="cols" px="4" templateColumns={claimGridColumns} mt="2">
                                {columns.map((col, i) => (
                                    <GridItem key={i} colSpan={1}>
                                        <Text fontSize={12} fontWeight={700} opacity={0.5}>
                                            {col}
                                        </Text>
                                    </GridItem>
                                ))}
                            </Grid>

                            {values.map(
                                (item, i) =>
                                    item !== 'fee' && (
                                        <ReadOnlyBullaItemRow
                                            key={i}
                                            columnString={claimGridColumns}
                                            hasDueDate={!!state.defaultValues.defaultDueDate}
                                            value={item}
                                            openCloseOverride={claimConfigurationOpenCloseDetailsOverride}
                                        />
                                    ),
                            )}
                        </Box>
                    ))}
                    {addBullaFee && (
                        <Box fontWeight={500}>
                            <BullaFeeRow
                                columnString={claimGridColumns}
                                amount={bullaFeeClaimParams.claimAmount}
                                tokenSymbol={bullaFeeClaimParams.token.symbol}
                                onTokenSelected={setFeeToken}
                                selectableTokens={selectableTokens}
                                transactionPending={executing}
                                usdAmount={bullaFeeUSD}
                            />
                        </Box>
                    )}
                </Stack>
            </ShadowBox>

            <Formik onSubmit={_ => handleCreate(batches[state.batchIndex])} initialValues={{}} key="form">
                <Form placeholder={''}>
                    <BatchFooter>
                        <Button
                            colorScheme="white"
                            color="dark"
                            border="1px"
                            borderColor="dark"
                            px="8"
                            py="6"
                            onClick={() =>
                                setWizardState({
                                    ...state,
                                    kind: 'ClaimConfiguration',
                                })
                            }
                        >
                            {'Back'}
                        </Button>
                        <Spacer />
                        <HStack spacing={4}>
                            {isContactsReady(contactsContext) && contactsToBeAdded(contactsContext).length !== 0 && (
                                <HStack>
                                    <Checkbox
                                        isChecked={!!state.saveToContacts}
                                        onChange={() => setWizardState({ ...state, saveToContacts: !state.saveToContacts })}
                                    />
                                    <Text>
                                        Save New Recipients To Contacts
                                        {` (${contactsToBeAdded(contactsContext).length})`}
                                    </Text>
                                </HStack>
                            )}
                            {!!state.defaultValues.defaultDueDate ? (
                                CreateOrangeButton(
                                    needsSignIn
                                        ? 'Sign In'
                                        : `Create Claims${batches.length == 1 ? '' : ` (${state.batchIndex + 1} of ${batches.length})`}`,
                                )({
                                    type: 'submit',
                                    isDisabled: executing,
                                })
                            ) : (
                                <InstantPaymentButton
                                    isDisabled={executing}
                                    isLoading={executing}
                                    paymentInfo={batches[state.batchIndex].map(v =>
                                        v == 'fee'
                                            ? { amount: bullaFeeClaimParams.claimAmount, token: bullaFeeClaimParams.token }
                                            : { amount: v.amount, token: v.token },
                                    )}
                                    colorScheme={'accent'}
                                    withText={currText => {
                                        if (currText.includes('Send') && batches.length > 1) {
                                            return `${currText} (${state.batchIndex + 1} of ${batches.length})`;
                                        }
                                        return currText;
                                    }}
                                    h="14"
                                    py="6"
                                    px="10"
                                />
                            )}
                        </HStack>
                    </BatchFooter>
                </Form>
            </Formik>
        </Container>
    );
};
