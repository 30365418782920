import { BigNumber } from 'ethers';
import { toNumberSafe } from '../../tools/common';
import { BullaItemEvent, ClaimEvent } from '../data-transforms';
import { BullaBankerCreatedEvent, BullaTagUpdatedEvent } from '../domain/bulla-banker-domain';
import {
    BullaManagerSetEvent,
    ClaimCreatedEvent,
    ClaimPaymentEvent,
    ClaimRejectedEvent,
    ClaimRescindedEvent,
    FeePaidEvent,
} from '../domain/bulla-claim-domain';
import { FinancingAcceptedEvent, FinancingOfferedEvent } from '../domain/bulla-finance-domain';
import { InstantPaymentEvent, InstantPaymentTagUpdatedEvent } from '../domain/bulla-instant-pay-domain';
import { ERC721TransferEvent, HumaFactorEvent } from '../domain/common-domain';
import {
    DepositMadeEvent,
    InvoiceFundedEvent,
    InvoiceKickbackAmountSentEvent,
    InvoiceUnfactoredEvent,
    SharesRedeemedEvent,
} from '../domain/factoring-domain';
import { LoanOfferAcceptedEvent, LoanOfferedEvent, LoanOfferRejectedEvent } from '../domain/frendlend-domain';
import { MappedEventType } from '../dto/mapped-event-types';
import { intToDate } from '../helpers';
import {
    BullaBankerCreatedEvent__graphql,
    BullaItemInfoLogs__graphql,
    BullaManagerSetEvent__graphql,
    BullaTagUpdatedEvent__graphql,
    ClaimCreatedEvent__graphql,
    ClaimLog__graphql,
    ClaimPaymentEvent__graphql,
    ClaimRejectedEvent__graphql,
    ClaimRescindedEvent__graphql,
    DepositMadeEvent__graphql,
    Event__graphql,
    FeePaidEvent__graphql,
    FinancingAcceptedEvent__graphql,
    FinancingOfferedEvent__graphql,
    InstantPaymentEvent__graphql,
    InstantPaymentTagUpdatedEvent__graphql,
    InvoiceFundedEvent__graphql,
    InvoiceKickbackAmountSentEvent__graphql,
    InvoiceUnfactoredEvent__graphql,
    LoanOfferAcceptedEvent__graphql,
    LoanOfferedEvent__graphql,
    LoanOfferRejectedEvent__graphql,
    SharesRedeemedEvent__graphql,
    TransferEvent__graphql,
} from './graph-domain';
import { HumaQueryResult } from './userQuery';

export const mapGraphEntityToTransferEvent = (ev: TransferEvent__graphql): ERC721TransferEvent => ({
    __typename: 'ERC721TransferEvent',
    eventType: ev.eventName,
    blockNumber: toNumberSafe(ev.blockNumber),
    txHash: ev.transactionHash,
    blocktime: intToDate(+ev.timestamp),
    from: ev.from,
    to: ev.to,
    tokenId: ev.tokenId,
    logIndex: toNumberSafe(ev.logIndex),
});

export const mapGraphEntityToBullaTagUpdatedEvent = (ev: BullaTagUpdatedEvent__graphql): BullaTagUpdatedEvent => ({
    __typename: ev.__typename,
    eventType: ev.eventName,
    blockNumber: toNumberSafe(ev.blockNumber),
    txHash: ev.transactionHash,
    blocktime: intToDate(+ev.timestamp),
    managerAddress: ev.bullaManager,
    tokenId: ev.claim.tokenId,
    updatedBy: ev.updatedBy,
    tag: ev.tag,
    logIndex: toNumberSafe(ev.logIndex),
});

export const mapGraphEntityToFeePaidEvent = (ev: FeePaidEvent__graphql): FeePaidEvent => ({
    __typename: ev.__typename,
    eventType: ev.eventName,
    blockNumber: toNumberSafe(ev.blockNumber),
    txHash: ev.transactionHash,
    blocktime: intToDate(+ev.timestamp),
    bullaManager: ev.bullaManager,
    tokenId: ev.claim.tokenId,
    collectionAddress: ev.collectionAddress,
    paymentAmount: BigNumber.from(ev.paymentAmount),
    transactionFee: BigNumber.from(ev.transactionFee),
    logIndex: toNumberSafe(ev.logIndex),
});

export const mapGraphEntityToClaimRejectedEvent = (ev: ClaimRejectedEvent__graphql): ClaimRejectedEvent => ({
    __typename: ev.__typename,
    eventType: ev.eventName,
    blockNumber: toNumberSafe(ev.blockNumber),
    txHash: ev.transactionHash,
    blocktime: intToDate(+ev.timestamp),
    tokenId: ev.claim.tokenId,
    managerAddress: ev.managerAddress,
    logIndex: toNumberSafe(ev.logIndex),
});

export const mapGraphEntityToClaimRescindedEvent = (ev: ClaimRescindedEvent__graphql): ClaimRescindedEvent => ({
    __typename: ev.__typename,
    eventType: ev.eventName,
    blockNumber: toNumberSafe(ev.blockNumber),
    txHash: ev.transactionHash,
    blocktime: intToDate(+ev.timestamp),
    bullaManager: ev.bullaManager,
    tokenId: ev.claim.tokenId,
    logIndex: toNumberSafe(ev.logIndex),
});

export const mapGraphEntityToClaimPaymentEvent = (ev: ClaimPaymentEvent__graphql): ClaimPaymentEvent => ({
    __typename: ev.__typename,
    eventType: ev.eventName,
    blockNumber: toNumberSafe(ev.blockNumber),
    txHash: ev.transactionHash,
    blocktime: intToDate(+ev.timestamp),
    bullaManager: ev.bullaManager,
    tokenId: ev.claim.tokenId,
    debtor: ev.debtor,
    paidBy: ev.paidBy,
    paymentAmount: BigNumber.from(ev.paymentAmount),
    logIndex: toNumberSafe(ev.logIndex),
    creditor: undefined,
});

export const mapGraphEntityToClaimCreatedEvent = (ev: ClaimCreatedEvent__graphql): ClaimCreatedEvent => ({
    __typename: ev.__typename,
    eventType: ev.eventName,
    blockNumber: toNumberSafe(ev.blockNumber),
    txHash: ev.transactionHash,
    blocktime: intToDate(+ev.timestamp),
    bullaManager: ev.bullaManager,
    tokenId: ev.claim.tokenId,
    debtor: ev.debtor,
    claimAmount: BigNumber.from(ev.amount),
    token: ev.claimToken.address,
    creditor: ev.creditor,
    description: ev.description,
    dueBy: intToDate(+ev.dueBy),
    ipfsHash: ev.ipfsHash ?? '',
    origin: ev.creator,
    parent: ev.parent,
    logIndex: toNumberSafe(ev.logIndex),
});

export const mapGraphEntityToLoanOfferedEvent = (ev: LoanOfferedEvent__graphql): LoanOfferedEvent => ({
    __typename: ev.__typename,
    eventType: ev.eventName,
    blockNumber: toNumberSafe(ev.blockNumber),
    txHash: ev.transactionHash,
    blocktime: intToDate(+ev.timestamp),
    interestBPS: +ev.interestBPS,
    termLength: BigNumber.from(ev.termLength),
    loanAmount: BigNumber.from(ev.loanAmount),
    creditor: ev.creditor,
    debtor: ev.debtor,
    description: ev.description,
    token: ev.claimToken.address,
    ipfsHash: ev.ipfsHash ?? '',
    loanId: ev.loanId,
    offeredBy: ev.offeredBy,
    logIndex: toNumberSafe(ev.logIndex),
});

export const mapGraphEntityToLoanOfferRejectedEvent = (ev: LoanOfferRejectedEvent__graphql): LoanOfferRejectedEvent => ({
    __typename: ev.__typename,
    eventType: ev.eventName,
    blockNumber: toNumberSafe(ev.blockNumber),
    txHash: ev.transactionHash,
    blocktime: intToDate(+ev.timestamp),
    rejectedBy: ev.rejectedBy,
    loanId: ev.loanId,
    logIndex: toNumberSafe(ev.logIndex),
});

export const mapGraphEntityToLoanAcceptedEvent = (ev: LoanOfferAcceptedEvent__graphql): LoanOfferAcceptedEvent => ({
    __typename: ev.__typename,
    eventType: ev.eventName,
    blockNumber: toNumberSafe(ev.blockNumber),
    txHash: ev.transactionHash,
    blocktime: intToDate(+ev.timestamp),
    tokenId: ev.claimId.id,
    loanId: ev.loanId,
    logIndex: toNumberSafe(ev.logIndex),
});

export const mapGraphEntityToInstantPaymentEvent = (ev: InstantPaymentEvent__graphql): InstantPaymentEvent => ({
    __typename: ev.__typename,
    from: ev.from.address,
    to: ev.to.address,
    amount: BigNumber.from(ev.amount),
    tokenAddress: ev.token.address,
    ipfsHash: ev.ipfsHash ?? '',
    description: ev.description,
    tag: ev.tag,
    eventType: ev.eventName,
    blockNumber: toNumberSafe(ev.blockNumber),
    txHash: ev.transactionHash,
    blocktime: intToDate(+ev.timestamp),
    logIndex: toNumberSafe(ev.logIndex),
});

export const mapGraphEntityToInstantPaymentTagUpdatedEvent = (
    ev: InstantPaymentTagUpdatedEvent__graphql,
): InstantPaymentTagUpdatedEvent => ({
    __typename: ev.__typename,
    txAndLogIndexHash: ev.instantPayment.id,
    updatedBy: ev.updatedBy.address,
    tag: ev.tag,
    eventType: ev.eventName,
    blockNumber: toNumberSafe(ev.blockNumber),
    txHash: ev.transactionHash,
    blocktime: intToDate(+ev.timestamp),
    logIndex: toNumberSafe(ev.logIndex),
});

export const mapGraphEntityToBullaManagerSetEvent = (ev: BullaManagerSetEvent__graphql): BullaManagerSetEvent => ({
    __typename: ev.__typename,
    eventType: ev.eventName,
    blockNumber: toNumberSafe(ev.blockNumber),
    txHash: ev.transactionHash,
    blocktime: intToDate(+ev.timestamp),
    newBullaManager: ev.newBullaManager,
    prevBullaManager: ev.prevBullaManager,
    logIndex: toNumberSafe(ev.logIndex),
});

export const mapGraphEntityToBullaBankerCreatedEvent = (ev: BullaBankerCreatedEvent__graphql): BullaBankerCreatedEvent => ({
    __typename: ev.__typename,
    eventType: ev.eventName,
    blockNumber: toNumberSafe(ev.blockNumber),
    txHash: ev.transactionHash,
    blocktime: intToDate(+ev.timestamp),
    bullaBanker: ev.bullaBanker,
    bullaClaimERC721: ev.bullaClaimERC721,
    bullaManager: ev.bullaManager,
    logIndex: toNumberSafe(ev.logIndex),
});

export const mapGraphEntityToFinancingAcceptedEvent = (ev: FinancingAcceptedEvent__graphql): FinancingAcceptedEvent => ({
    __typename: ev.__typename,
    eventType: ev.eventName,
    blockNumber: toNumberSafe(ev.blockNumber),
    txHash: ev.transactionHash,
    blocktime: intToDate(+ev.timestamp),
    logIndex: toNumberSafe(ev.logIndex),
    tokenId: ev.financedClaimId.tokenId,
    originatingClaimId: ev.originatingClaimId.tokenId,
});

export const mapGraphEntityToFinancingOfferedEvent = (ev: FinancingOfferedEvent__graphql): FinancingOfferedEvent => ({
    __typename: ev.__typename,
    eventType: ev.eventName,
    blockNumber: toNumberSafe(ev.blockNumber),
    txHash: ev.transactionHash,
    blocktime: intToDate(+ev.timestamp),
    logIndex: toNumberSafe(ev.logIndex),
    tokenId: ev.originatingClaimId.tokenId,
    minDownPaymentBPS: ev.minDownPaymentBPS,
    interestBPS: ev.interestBPS,
    termLength: BigNumber.from(ev.termLength),
});

export const mapHumaGraphEntitiesToClaimPaymentEvents = (creditEvents: HumaQueryResult['creditEvents']): HumaFactorEvent[] => {
    return creditEvents
        .filter(ev => ev.event === 6 || ev.event === 3 || ev.event === 4)
        .map((ev, index) => {
            const isDrawdownEvent = ev.event === 3;
            const isPaymentMadeEvent = ev.event === 4;
            const eventTypeName = isDrawdownEvent
                ? 'DrawdownOnReceivableEvent'
                : isPaymentMadeEvent
                ? 'PaymentMadeEvent'
                : 'ExtraFundsDispersedEvent';

            return {
                __typename: eventTypeName,
                blocktime: intToDate(+ev.timestamp),
                txHash: ev.tx,
                amount: ev.amount,
                netAmountToBorrower: ev.netAmountToBorrower ?? ev.amount,
                owner: ev.owner,
                logIndex: index,
                tokenId: ev.receivableParam,
                blockNumber: 0,
                eventType: eventTypeName,
            };
        });
};

export const mapGraphEntityToInvoiceFundedEvent = (ev: InvoiceFundedEvent__graphql): InvoiceFundedEvent => {
    return {
        __typename: ev.__typename,
        eventType: ev.eventName,
        blockNumber: toNumberSafe(ev.blockNumber),
        txHash: ev.transactionHash,
        blocktime: intToDate(+ev.timestamp),
        fundedAmount: BigNumber.from(ev.fundedAmount),
        originalCreditor: ev.originalCreditor,
        logIndex: toNumberSafe(ev.logIndex),
        tokenId: ev.invoiceId,
        poolAddress: ev.poolAddress,
        priceAfterTransaction: BigNumber.from(ev.priceAfterTransaction),
    };
};

export const mapGraphEntityToInvoiceKickbackAmountSentEvent = (
    ev: InvoiceKickbackAmountSentEvent__graphql,
): InvoiceKickbackAmountSentEvent => {
    return {
        __typename: ev.__typename,
        eventType: ev.eventName,
        blockNumber: toNumberSafe(ev.blockNumber),
        txHash: ev.transactionHash,
        blocktime: intToDate(+ev.timestamp),
        kickbackAmount: BigNumber.from(ev.kickbackAmount),
        originalCreditor: ev.originalCreditor,
        logIndex: toNumberSafe(ev.logIndex),
        tokenId: ev.invoiceId,
        poolAddress: ev.poolAddress,
        priceAfterTransaction: BigNumber.from(ev.priceAfterTransaction),
    };
};

export const mapGraphEntityToInvoiceUnfactoredEvent = (ev: InvoiceUnfactoredEvent__graphql): InvoiceUnfactoredEvent => {
    return {
        __typename: ev.__typename,
        eventType: ev.eventName,
        blockNumber: toNumberSafe(ev.blockNumber),
        txHash: ev.transactionHash,
        blocktime: intToDate(+ev.timestamp),
        originalCreditor: ev.originalCreditor,
        logIndex: toNumberSafe(ev.logIndex),
        tokenId: ev.invoiceId,
        totalRefundAmount: BigNumber.from(ev.totalRefundAmount),
        interestToCharge: BigNumber.from(ev.interestToCharge),
        poolAddress: ev.poolAddress,
        priceAfterTransaction: BigNumber.from(ev.priceAfterTransaction),
    };
};

export const mapGraphEntityToDepositMadeEvent = (ev: DepositMadeEvent__graphql): DepositMadeEvent => {
    return {
        __typename: ev.__typename,
        eventType: ev.eventName,
        blockNumber: toNumberSafe(ev.blockNumber),
        txHash: ev.transactionHash,
        blocktime: intToDate(+ev.timestamp),
        depositor: ev.depositor,
        assets: BigNumber.from(ev.assets),
        sharesIssued: BigNumber.from(ev.sharesIssued),
        poolAddress: ev.poolAddress,
        logIndex: toNumberSafe(ev.logIndex),
        priceAfterTransaction: BigNumber.from(ev.priceAfterTransaction),
        ipfsHash: ev.ipfsHash,
    };
};

export const mapGraphEntityToSharesRedeemedEvent = (ev: SharesRedeemedEvent__graphql): SharesRedeemedEvent => {
    return {
        __typename: ev.__typename,
        eventType: ev.eventName,
        blockNumber: toNumberSafe(ev.blockNumber),
        txHash: ev.transactionHash,
        blocktime: intToDate(+ev.timestamp),
        redeemer: ev.redeemer,
        assets: BigNumber.from(ev.assets),
        shares: BigNumber.from(ev.shares),
        poolAddress: ev.poolAddress,
        logIndex: toNumberSafe(ev.logIndex),
        priceAfterTransaction: BigNumber.from(ev.priceAfterTransaction),
        ipfsHash: ev.ipfsHash,
    };
};

export const mapGraphEventToEvent = <T extends Event__graphql>(ev: T): MappedEventType => {
    switch (ev.__typename) {
        case 'TransferEvent':
            return mapGraphEntityToTransferEvent(ev);
        case 'BullaTagUpdatedEvent':
            return mapGraphEntityToBullaTagUpdatedEvent(ev);
        case 'FeePaidEvent':
            return mapGraphEntityToFeePaidEvent(ev);
        case 'ClaimRejectedEvent':
            return mapGraphEntityToClaimRejectedEvent(ev);
        case 'ClaimRescindedEvent':
            return mapGraphEntityToClaimRescindedEvent(ev);
        case 'ClaimPaymentEvent':
            return mapGraphEntityToClaimPaymentEvent(ev);
        case 'ClaimCreatedEvent':
            return mapGraphEntityToClaimCreatedEvent(ev);
        case 'BullaBankerCreatedEvent':
            return mapGraphEntityToBullaBankerCreatedEvent(ev);
        case 'BullaManagerSetEvent':
            return mapGraphEntityToBullaManagerSetEvent(ev);
        case 'InstantPaymentEvent':
            return mapGraphEntityToInstantPaymentEvent(ev);
        case 'InstantPaymentTagUpdatedEvent':
            return mapGraphEntityToInstantPaymentTagUpdatedEvent(ev);
        case 'FinancingAcceptedEvent':
            return mapGraphEntityToFinancingAcceptedEvent(ev);
        case 'FinancingOfferedEvent':
            return mapGraphEntityToFinancingOfferedEvent(ev);
        case 'LoanOfferedEvent':
            return mapGraphEntityToLoanOfferedEvent(ev);
        case 'LoanOfferRejectedEvent':
            return mapGraphEntityToLoanOfferRejectedEvent(ev);
        case 'LoanOfferAcceptedEvent':
            return mapGraphEntityToLoanAcceptedEvent(ev);
        case 'InvoiceFundedEvent':
            return mapGraphEntityToInvoiceFundedEvent(ev);
        case 'InvoiceKickbackAmountSentEvent':
            return mapGraphEntityToInvoiceKickbackAmountSentEvent(ev);
        case 'InvoiceUnfactoredEvent':
            return mapGraphEntityToInvoiceUnfactoredEvent(ev);
        case 'DepositMadeEvent':
            return mapGraphEntityToDepositMadeEvent(ev);
        case 'SharesRedeemedEvent':
            return mapGraphEntityToSharesRedeemedEvent(ev);
    }
};

export const mapGraphEntitiesToEvents = (eventEntities: Event__graphql[]): MappedEventType[] => eventEntities.map(mapGraphEventToEvent);

export const mapGraphEntitiesToBullaItemEvents = (eventEntities: BullaItemInfoLogs__graphql[]): BullaItemEvent[] =>
    eventEntities.map(mapGraphEventToEvent) as BullaItemEvent[];

export const mapClaimLogGraphEventsToClaimEvents = (eventEntities: ClaimLog__graphql[]) =>
    eventEntities.map(mapGraphEventToEvent) as ClaimEvent[];
