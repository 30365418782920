import { Box, Container, Image, VStack } from '@chakra-ui/react';
import { Clipboard, Sparkle, Wallet } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FileIcon from 'url:../../../assets/file-icon.svg';
import { useAuthApi } from '../../../hooks/useAuthApi';
import { useMembership } from '../../../hooks/useMembership';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { PageLayoutProvider } from '../../layout/page-layout';
import { BullaCloseButton } from '../common';
import { AddWalletsChainsCard, WalletChainSelection } from '../common-reporting/add-wallets-chains-card';
import { ProgressSteps, Step } from '../common-reporting/progress-steps';
import { ReportNameCard } from '../common-reporting/report-name-card';
import { AddRecipientsCard } from './add-recipients-card';

type ReportNameStep = { step: 'reportName'; reportName: string };
type SelectOwnWalletsStep = Omit<ReportNameStep, 'step'> & { step: 'selectOwnWallets' };
type AddRecipientsStep = Omit<SelectOwnWalletsStep, 'step'> & { step: 'addRecipients'; walletChainSelection: WalletChainSelection };
type BuildReportStep = Omit<AddRecipientsStep, 'step'> & { step: 'buildReport'; recipientWallets: Set<string> };

type _1099WizardState = ReportNameStep | SelectOwnWalletsStep | AddRecipientsStep | BuildReportStep;

type _1099WizardStep = _1099WizardState['step'];

const steps: Step<_1099WizardStep>[] = [
    {
        icon: Clipboard,
        label: 'Start 1099 Report',
        step: 'reportName',
    },
    { icon: Wallet, label: 'Add Wallets', step: 'selectOwnWallets' },
    { icon: Wallet, label: 'Add Recipients', step: 'addRecipients' },
    { icon: Sparkle, label: 'Build report', step: 'buildReport' },
];

const mapStepToProgress = (step: _1099WizardStep): number => {
    switch (step) {
        case 'reportName':
            return 0;
        case 'selectOwnWallets':
            return 33;
        case 'addRecipients':
            return 66;
        case 'buildReport':
            return 100;
        default:
            return 0;
    }
};

export const _1099Wizard: React.FC = () => {
    const navigate = useNavigate();
    const onClose = () => navigate('/reporting');
    const actingWallet = useActingWalletAddress();
    const [wizardState, setWizardState] = useState<_1099WizardState>({ step: 'reportName', reportName: '' });
    const { getFullMembershipInfo } = useAuthApi();
    const membership = useMembership();

    const [selectableWallets, setSelectableWallets] = useState<Set<string>>(new Set());

    const membershipIds = membership?.membershipIds ?? [];

    useEffect(() => {
        if (membership == null) return;
        if (membership.isFreeTrial || membershipIds.length == 0) {
            setSelectableWallets(new Set([actingWallet.toLowerCase()]));
            return;
        }

        Promise.all(membershipIds.map(getFullMembershipInfo)).then(x => {
            const selectableWallets = new Set(
                x.flatMap(dto => (dto ? [dto.mainAddress, ...dto.additionalAddresses] : [])).map(x => x.toLowerCase()),
            );
            return setSelectableWallets(selectableWallets);
        });
    }, [membershipIds, membership]);

    const startCard = wizardState.step === 'reportName' && (
        <ReportNameCard
            image={<Image src={FileIcon} />}
            welcomeMessage="Welcome to 1099 Generator"
            explanation="Export 1099 information for selected wallets and recipients."
            reportName={wizardState.reportName}
            setReportName={str => setWizardState(prev => ({ ...prev, reportName: str }))}
            onStartExport={() => setWizardState(prev => ({ ...prev, step: 'selectOwnWallets' }))}
        />
    );

    const selectOwnWalletsCard = wizardState.step === 'selectOwnWallets' && (
        <AddWalletsChainsCard
            selectableWallets={selectableWallets}
            onContinue={walletChainSelection =>
                setWizardState(prev => ({
                    ...(prev as SelectOwnWalletsStep),
                    step: 'addRecipients',
                    walletChainSelection,
                }))
            }
            onCancel={() =>
                setWizardState(prev => ({
                    ...prev,
                    step: 'reportName',
                }))
            }
        />
    );

    const addRecipientsCard = wizardState.step === 'addRecipients' && (
        <AddRecipientsCard
            ownWallets={wizardState.walletChainSelection.addresses}
            onContinue={wallets =>
                setWizardState(prev => ({
                    ...(prev as AddRecipientsStep),
                    step: 'buildReport',
                    recipientWallets: wallets,
                }))
            }
            onCancel={() =>
                setWizardState(prev => ({
                    ...prev,
                    step: 'selectOwnWallets',
                }))
            }
        />
    );

    return (
        <PageLayoutProvider>
            <Box h="100vh" display={'flex'} flexDir="column" flex="1 1 auto" overflowY="scroll" p="12">
                <Box display="flex" justifyContent="flex-end" width="100%">
                    <BullaCloseButton onClose={onClose} />
                </Box>
                <Container maxW="xl" p="0">
                    <VStack spacing={8} align="center">
                        {startCard}
                        {selectOwnWalletsCard}
                        {addRecipientsCard}
                        <ProgressSteps mapStepToProgress={mapStepToProgress} steps={steps} currentStep={wizardState.step} />
                    </VStack>
                </Container>
            </Box>
        </PageLayoutProvider>
    );
};
