import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    chakra,
    Flex,
    Heading,
    HStack,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react';
import axios from 'axios';
import { Wallet } from 'phosphor-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { InstantPaymentEvent } from '../../data-lib/domain/bulla-instant-pay-domain';
import { getUniqueEventId } from '../../data-lib/dto/events-dto';
import { addressEquality, isValidAddress } from '../../data-lib/ethereum';
import {
    BULLA_COLLECTION_ADDRESS,
    ChainId,
    changeNetwork,
    EXTERNAL_TRANSACTIONS_SUPPORTED_NETWORKS,
    NETWORKS,
    TokenDto,
    TokenInfo,
    TokenList,
    TXStatus,
} from '../../data-lib/networks';
import { isStablecoin } from '../../data-lib/tokens';
import { useTokenBalances } from '../../hooks/useChainData';
import { useInstantPayment } from '../../hooks/useInstantPayment';
import { useLocalStorage } from '../../hooks/useStorage';
import { useActingWalletAddress } from '../../hooks/useWalletAddress';
import { useWeb3 } from '../../hooks/useWeb3';
import { ReportingBadge } from '../../pages/reporting/reporting-explorer';
import { tryGetSpecificInstantPayment } from '../../state/state-helpers';
import { BULLA_NETWORK_DISCORD_INVITE, deleteBullaCookie, endpoints, PremiumTier, toDisplayLabel } from '../../tools/common';
import { testingUpgradePath } from '../../tools/featureFlags';
import { STORAGE_KEYS } from '../../tools/storage';
import { ContactNameOrAddress } from '../base/address-label';
import { InstantPaymentButton } from '../display/claim-action-buttons';
import { OrangeButton } from '../inputs/buttons';
import { CloseModalButton } from './common';
import { IconBox } from './common-reporting/progress-steps';
import { ChainSelector, RecipientField } from './create-claim-modal/create-claim-inputs';
import { CreateClaimFields } from './create-claim-modal/create-claim-modal';
import { Feature, PricingBox } from './importer-free-pricing-modal';

type WaitingForGraphTransaction = { id: string; chainId: ChainId; pendingMembershipId: string };
type AddPendingMembershipResponse = { pendingMembershipId: string };

const supportedPremiumPaymentNetworks = [...EXTERNAL_TRANSACTIONS_SUPPORTED_NETWORKS] as ChainId[];

export const getPriceForPremiumTier = (tier: PremiumTier) => {
    switch (tier) {
        case 'basic':
            return testingUpgradePath ? '1' : '49';
        case 'pro':
            return testingUpgradePath ? '2' : '199';
    }
};

export const getMaximumWalletsPerPremiumTier = (tier: PremiumTier) => {
    switch (tier) {
        case 'basic':
            return 2;
        case 'pro':
            return 6;
    }
};

type PurchasePremiumProps = { tier: PremiumTier; onClose: () => void; modalContentRef?: React.RefObject<HTMLDivElement> };

export interface WelcomePremiumData {
    isWelcome: boolean;
    tier: PremiumTier | null;
}

export const PurchasePremium: React.FC<PurchasePremiumProps> = ({ tier, onClose, modalContentRef }) => {
    const { connectedNetwork, connectedNetworkConfig, userAddress } = useWeb3();
    const tokenList: TokenList = connectedNetworkConfig.supportedTokens;
    const stablecoins: TokenInfo[] = useMemo(() => {
        return Object.values(tokenList).filter(x => isStablecoin(x.variant));
    }, [connectedNetwork]);
    const [creatingInstantPayment, { createInstantPayment }] = useInstantPayment();
    const [waitingForMembership, setWaitingForMembership] = useState<WaitingForGraphTransaction>();
    const [listenIncrement, setListenIncrement] = useState(0);
    const [handlingPayment, setHandlingPayment] = React.useState(false);
    const isLoading = creatingInstantPayment || waitingForMembership !== undefined || handlingPayment;
    const actingWallet = useActingWalletAddress();
    const [, setWelcomePremiumData] = useLocalStorage<WelcomePremiumData>(`${STORAGE_KEYS.welcomePremium}#${actingWallet}`, {
        isWelcome: false,
        tier: null,
    });
    const tokenBalances = useTokenBalances({ chainId: connectedNetwork, poll: true });
    const premiumPrice = useMemo(() => getPriceForPremiumTier(tier), [tier]);
    const [selectedWallets, setSelectedWallets] = useState<Set<string>>(new Set());
    const maxAdditionalWallets = React.useMemo(() => getMaximumWalletsPerPremiumTier(tier) - 1, [tier]);

    const sortedStablecoins = useMemo(() => {
        const stablecoinsWithBalance = stablecoins.map(tokenInfo => {
            const balance = tokenBalances.getBalanceForToken(tokenInfo.token.address)?.toString() ?? '0';
            return { ...tokenInfo, balance };
        });

        stablecoinsWithBalance.sort((a, b) => parseFloat(b.balance) - parseFloat(a.balance));

        return stablecoinsWithBalance;
    }, [stablecoins, tokenBalances]);

    const initialToken = sortedStablecoins && sortedStablecoins[0].token;
    const [selectedToken, setSelectedToken] = useState<TokenDto>(initialToken);

    const listenForMembership = async ({ id, chainId, pendingMembershipId }: WaitingForGraphTransaction) => {
        console.debug('listeining for member', { id });

        const payment = await tryGetSpecificInstantPayment(NETWORKS[chainId].connections.graphEndpoint!, id);
        if (!!payment) {
            console.debug('found', { payment });

            try {
                const { status } = await axios.post(
                    `${endpoints.authApi}/${actingWallet}/membership/associate`,
                    { pendingMembershipId, chainId, instantPaymentId: id },
                    {
                        withCredentials: true,
                    },
                );

                if (status == 200) {
                    setWelcomePremiumData({ isWelcome: true, tier });
                    deleteBullaCookie(userAddress);
                    setTimeout(() => window.location.replace(window.location.origin), 1000);
                    return;
                }
            } catch (e) {
                console.error('unable to associate membership to payment', e);
            }
        }
        if (waitingForMembership) {
            setTimeout(() => setListenIncrement(x => x + 1), 2000);
        }
    };

    React.useEffect(() => {
        if (!!waitingForMembership) listenForMembership(waitingForMembership);
    }, [waitingForMembership, listenIncrement]);

    const selectNetworks =
        supportedPremiumPaymentNetworks.includes(connectedNetworkConfig.chainId) || testingUpgradePath
            ? connectedNetworkConfig.name
            : 'Select Network';

    useEffect(() => setSelectedToken(initialToken), [connectedNetwork]);

    const handlePayment = async (token: TokenDto, amount: string) => {
        try {
            setHandlingPayment(true);
            const {
                data: { pendingMembershipId },
            } = await axios.post<AddPendingMembershipResponse>(
                `${endpoints.authApi}/${actingWallet}/membership/pending`,
                {
                    mainAddress: actingWallet,
                    tier,
                    additionalAddresses: Array.from(selectedWallets).filter(x => x !== actingWallet),
                },
                {
                    withCredentials: true,
                },
            );

            const args: CreateClaimFields = {
                token,
                claimAmount: amount.toString(),
                instantPayment: true,
                recipient: BULLA_COLLECTION_ADDRESS,
                description: 'Bulla Premium Membership',
                dueBy: new Date(),
                tags: [],
            };

            const result = await createInstantPayment(args);

            if (result?.status == TXStatus.SUCCESS) {
                const [instantPaymentEvent] = result.events.filter((x): x is InstantPaymentEvent => x.__typename == 'InstantPaymentEvent');
                const id = getUniqueEventId({ txHash: instantPaymentEvent.txHash, logIndex: instantPaymentEvent.logIndex });
                setWaitingForMembership({ id, chainId: connectedNetwork, pendingMembershipId });
            }
        } catch (error) {
            console.log('Error while creating instant payment:', error);
        } finally {
            setHandlingPayment(false);
        }
    };

    const handleAddWallet = (wallet: string) => {
        setSelectedWallets(old => {
            if (wallet && wallet !== '' && isValidAddress(wallet) && old.size < maxAdditionalWallets) return new Set([...old, wallet]);
            else return old;
        });
    };

    return (
        <>
            <ModalBody fontSize={'14px'} fontWeight="400" mt="5">
                <Box px="4" ref={modalContentRef}>
                    <IconBox icon={Wallet} boxShadow="none" w="fit-content" mb="6" />
                    <Heading as="h2" size="md" mb="2">
                        Add Your Wallets & Pay
                    </Heading>
                    <Text mb="6" color="gray.600">
                        Select the wallets you want to group under your Bulla {toDisplayLabel(tier)} package. You can add or remove wallets
                        in settings.
                    </Text>

                    <Box my={3}>
                        <RecipientField
                            initialValue={''}
                            field={{
                                name: 'selectedWallets',
                                value: selectedWallets,
                                onBlur: undefined,
                                onChange: e => setSelectedWallets(e.target.value),
                            }}
                            isDisabled={selectedWallets.size >= maxAdditionalWallets || handlingPayment}
                            setRecipient={handleAddWallet}
                            placeholder="Search for a wallet"
                            hasQrScanner={false}
                            inputLeftElement={<SearchIcon color="gray.300" />}
                            dropdownModalRef={modalContentRef}
                        />
                    </Box>

                    <VStack align="stretch" spacing={3} my={3}>
                        {[actingWallet, ...Array.from(selectedWallets)].map(wallet => (
                            <HStack key={wallet} justify="space-between" my="-1">
                                <HStack spacing={2} flex={1}>
                                    <ContactNameOrAddress chainId={NETWORKS[connectedNetwork].chainId} excludeYouBadge removeParenthesis>
                                        {wallet}
                                    </ContactNameOrAddress>
                                    {addressEquality(wallet, userAddress) && (
                                        <ReportingBadge backgroundColor="#ECFDF3" color="#067647" borderColor="#ABEFC6">
                                            Connected
                                        </ReportingBadge>
                                    )}
                                </HStack>
                                {!addressEquality(wallet, userAddress) && (
                                    <Button
                                        size="sm"
                                        colorScheme="red"
                                        variant="ghost"
                                        onClick={() => setSelectedWallets(new Set([...selectedWallets].filter(w => w !== wallet)))}
                                    >
                                        Remove
                                    </Button>
                                )}
                            </HStack>
                        ))}
                    </VStack>
                    <Text fontSize="16px" fontWeight="600">
                        Payment
                    </Text>
                    <Stack spacing={4} pt="3">
                        <Box>
                            <Text fontSize="14px" fontWeight="500" mb="2">
                                Chain
                            </Text>
                            <ChainSelector
                                chainId={connectedNetwork}
                                isDisabled={isLoading}
                                selectableChains={supportedPremiumPaymentNetworks}
                                textAlign={'left'}
                                onChainSelected={changeNetwork}
                                w="100%"
                            />
                        </Box>
                        <Box>
                            <Text fontSize="14px" fontWeight="500" mb="2">
                                Token
                            </Text>
                            <Menu>
                                {({ isOpen }) => (
                                    <>
                                        <MenuButton
                                            as={Button}
                                            rightIcon={<ChevronDownIcon />}
                                            variant="outline"
                                            isDisabled={selectNetworks === 'Select Network' || isLoading}
                                            borderColor="#E2E8F0"
                                            width="100%"
                                        >
                                            <Text textStyle="noWrap" fontSize="14px" textAlign="left" fontWeight="400">
                                                {selectedToken.symbol}
                                            </Text>
                                        </MenuButton>
                                        <MenuList display={isOpen ? 'block' : 'none'}>
                                            {sortedStablecoins.map(({ token }) => (
                                                <MenuItem key={token.address.toString()} onClick={() => setSelectedToken(token)}>
                                                    <Text textAlign="left" fontWeight="400">
                                                        {token.symbol}
                                                    </Text>
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </>
                                )}
                            </Menu>
                        </Box>
                        <Box>
                            <Flex
                                borderWidth="1px"
                                borderColor="gray.200"
                                borderRadius="md"
                                p="3"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Text fontWeight="500">Amount</Text>
                                <Text fontSize="16px" fontWeight="600">
                                    $ {premiumPrice}
                                </Text>
                            </Flex>
                        </Box>
                    </Stack>
                    {waitingForMembership !== undefined && (
                        <Flex alignItems={'center'} textAlign="center" pt="4">
                            <Text>Payment successful! Please wait to be onboarded.</Text>
                        </Flex>
                    )}
                </Box>
            </ModalBody>
            <ModalFooter>
                <Stack direction="row" spacing={4} width="100%">
                    <Button variant="outline" flex={1} py="6" onClick={onClose}>
                        Cancel
                    </Button>
                    <InstantPaymentButton
                        paymentInfo={[{ token: selectedToken!, amount: premiumPrice }]}
                        isLoading={isLoading}
                        isDisabled={isLoading}
                        flex={1}
                        withText={currText => {
                            if (currText === 'Send Payment') {
                                return 'Pay';
                            }
                            return currText;
                        }}
                        onClick={() => handlePayment(selectedToken, premiumPrice)}
                    />
                </Stack>
            </ModalFooter>
        </>
    );
};

export const PremiumPricingModal: React.FC<{ modalOpen: boolean; closeModal: () => void }> = ({ modalOpen, closeModal }) => {
    const [purchaseView, setPurchaseView] = useState<PremiumTier | undefined>();
    const modalContentRef = useRef<HTMLDivElement>(null);

    const onClose = () => {
        closeModal();
        setPurchaseView(undefined);
    };

    return (
        <>
            <Modal
                isCentered
                isOpen={modalOpen}
                onClose={onClose}
                motionPreset="slideInBottom"
                size={purchaseView ? 'md' : '2xl'}
                closeOnOverlayClick={false}
                closeOnEsc={false}
                scrollBehavior="inside"
            >
                <ModalOverlay />
                <ModalContent>
                    <CloseModalButton onClose={() => onClose()} />
                    {!purchaseView ? (
                        <>
                            <ModalHeader>
                                <Text color="gray.700" fontWeight={'700'} fontSize="25px" alignSelf="center" maxW={'95%'}>
                                    Bulla Premium Feature
                                </Text>
                            </ModalHeader>
                            <ModalBody fontSize={'14px'} fontWeight="400">
                                <Stack>
                                    <Box pb="2" mt="-3">
                                        <Text fontWeight={400} fontSize={'16px'}>
                                            {' '}
                                            Access our premium transaction journal feature to import and document past transactions.
                                        </Text>
                                    </Box>

                                    <PricingBox price={49} />

                                    <Text fontSize={'14px'}>
                                        What you get with <Text as="b">Bulla Premium:</Text>
                                    </Text>
                                    <Stack spacing={1} fontWeight={500}>
                                        <Feature feature={'External Transaction imports and categorization'} />
                                        <Feature feature={'Private notes only you can see'} />
                                    </Stack>

                                    <Box pt="1" color="#A3AED0" fontWeight={500}>
                                        <chakra.span>
                                            <chakra.span>Have questions or more support needs? </chakra.span>
                                            <Link
                                                href={BULLA_NETWORK_DISCORD_INVITE}
                                                isExternal
                                                target={'_blank'}
                                                textDecoration={'underline'}
                                                color={'#12525B'}
                                                fontWeight={500}
                                            >
                                                Reach out
                                            </Link>
                                            <chakra.span> to our sales team.</chakra.span>
                                        </chakra.span>
                                    </Box>
                                </Stack>
                            </ModalBody>
                            <ModalFooter>
                                <Spacer />
                                <OrangeButton onClick={() => setPurchaseView('basic')}>Pay now</OrangeButton>
                            </ModalFooter>
                        </>
                    ) : (
                        <PurchasePremium tier={purchaseView} onClose={onClose} modalContentRef={modalContentRef} />
                    )}
                </ModalContent>
            </Modal>
        </>
    );
};
